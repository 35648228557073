import React from "react";

import { Select, makeStyles, FormControl, InputLabel } from "@material-ui/core";

const useStyles = makeStyles({
  select: {
    border: "1px solid #828282",
    minWidth: "120px",
    borderRadius: "5px",
    padding: "0.5em",
    color : "#fff ",
    "&:before": {
      content: "",
      display: "none",
    },
    "&:after": {
      borderColor: "white",
      content: "",
      display: "none",
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: "white",
    },
  },
  selectLabel: {
    // top : "0.5em",
    left: "0.5em",
    color : "#fff",
    top: "50%",
    transform:" translateY(calc(-50% + 8px))",
    "&.MuiFormLabel-filled" : {
      color : "#fff",
      top : "0.5em",
      transform:" translateY(0)",
      background : "#1f1f1f",
      padding : "0 5px",
      zIndex : 1,
    }
  },
});

const CustomSelect = (props) => {
  const classes = useStyles();

  return (
    <>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel
          id="demo-controlled-open-select-label"
          className={classes.selectLabel}
        >
          {props.label}
        </InputLabel>
        <Select
          className={classes.select}
          {...props}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            getContentAnchorEl: null,
          }}
        ></Select>
      </FormControl>
    </>
  );
};

export default CustomSelect;
