import React, { useEffect, useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  fetchPaymentDetailsStart,
  fetchSingleTokenStart,
  saveTokenContractAddrStart,
  saveTokenPaymentAutoStart,
} from "../store/actions/TokenAction";
import { Redirect, useParams } from "react-router";
import Web3 from "web3";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import price from "crypto-price";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../Helper/NotificationMessage";
import { createNotification } from "react-redux-notify";

const CreateTokenConfirmation = (props) => {
  const { token_unique } = useParams();

  useEffect(() => {
    props.dispatch(
      fetchSingleTokenStart({ crypto_token_unique_id: token_unique })
    );
    props.dispatch(fetchPaymentDetailsStart());
    // Just loading blockchain.
    loadWeb3();
    // get amount api call.
    // get the wallet address api call.
    setCryptoPayment();
  }, []);

  const etherNetID = 1;

  const binanceNetID = 56;

  const polygonNetID = 137;

  const [walletAddress, setWalletAddress] = useState("");

  const [loadinBlockchain, setLoadingBlockchain] = useState(true);

  const [loading, setLoading] = useState(true);

  const [account, setAccount] = useState("");

  const [ethBalance, setEthBalance] = useState("0");

  const [token, setToken] = useState("");

  const [crytoSymbol, setCrytoSymbol] = useState("ETH");

  const [gasEstimation, setGasEstimation] = useState("0");

  const [metamaskNetStatus, setMetamaskNetStatus] = useState("ether");

  const [deployContractStatus, setDeployContractStatus] = useState(false);

  const [paymentCompleted, setPaymentCompleted] = useState(false);

  const [etherAmount, setEtherAmount] = useState("0");

  const [bnbAmount, setBnbAmount] = useState("0");

  const [polygonAmount, setPolygonAmount] = useState("0");

  const [payMeButtonContent, setPayMeButtonContent] = useState("");

  const [deployContractButtonContent, setDeployContractButtonContent] =
    useState("");

  const [manualTransNetType, setManualTransNetType] = useState("ether");

  useEffect(() => {
    if (payMeButtonContent !== "") {
      window.onbeforeunload = function () {
        return true;
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [payMeButtonContent]);

  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      console.log("Etherum enabled");
      setLoadingBlockchain(false);
      loadBlockchainData();
      return true;
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
      setLoadingBlockchain(false);
      loadBlockchainData();
      return true;
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
      return false;
    }
  };

  const findNet = async () => {
    const web3 = window.web3;
    const getNetworkID = await web3.eth.net.getId();
    switch (getNetworkID) {
      case etherNetID:
        setMetamaskNetStatus("ether");
        return "ETH";
        break;
      case binanceNetID:
        setMetamaskNetStatus("bnb");
        return "BNB";
        break;
      case polygonNetID:
        setMetamaskNetStatus("polygon");
        return "MATIC";
        break;
      default:
        setMetamaskNetStatus("");
        return "";
        break;
    }
  };

  const setCryptoPayment = async () => {
    price
      .getBasePrice("USD", "ETH")
      .then((value) => {
        console.log("ether", value.price);
        setEtherAmount(value.price);
      })
      .catch((err) => {
        console.log(err);
      });
    price
      .getBasePrice("USD", "BNB")
      .then((value) => {
        console.log("bnb", value.price);
        setBnbAmount(value.price);
      })
      .catch((err) => {
        console.log(err);
      });
    price
      .getBasePrice("USD", "MATIC")
      .then((value) => {
        console.log("matic", value.price);
        setPolygonAmount(value.price);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadBlockchainData = async () => {
    const web3 = window.web3;
    const accounts = await web3.eth.getAccounts();
    setAccount(accounts[0]);

    const ethBalance = await web3.eth.getBalance(accounts[0]);
    setEthBalance(ethBalance);

    findNet().then((val) => setCrytoSymbol(val));
    setLoading(false);
  };

  const deployContract = async (TokenDetails, values) => {
    setDeployContractButtonContent("Initiated.. Please wait...");
    const web3 = window.web3;
    const erc20Token = new web3.eth.Contract(TokenDetails.abi);
    const max_token_supply = values.max_token_supply.toString();
    try {
      const res = await erc20Token
        .deploy({
          data: TokenDetails.bytecode,
          arguments: [
            values.name,
            values.token_symbol,
            max_token_supply,
            values.token_decimals,
          ],
        })
        .send(
          {
            from: account,
            gas: 5000000,
            gasPrice: 25000000000,
          },
          function (error, transactionHash) {
            console.log("Txt", transactionHash);
          }
        )
        .on("confirmation", (confirmationNumber, receipt) => {
          console.log("con", confirmationNumber);
        })
        .then(async (newContractInstance) => {
          console.log("New token created.", newContractInstance.address);
          console.log(
            "name",
            await newContractInstance.methods.name.call().toString()
          );
          props.dispatch(
            saveTokenContractAddrStart({
              crypto_token_id:
                props.tokenDetails.data.crypto_token.crypto_token_id,
              contract_address: newContractInstance.options.address,
            })
          );
          const notificationMessage = getSuccessNotificationMessage(
            "Contract deployed. Please check the metamask.."
          );
          props.dispatch(createNotification(notificationMessage));
          console.log(newContractInstance.options.address); // instance with the new contract address
          setDeployContractButtonContent("");
        });
    } catch (error) {
      const notificationMessage = getErrorNotificationMessage(
        "Failed. Please try again..."
      );
      props.dispatch(createNotification(notificationMessage));
      setDeployContractButtonContent("");
    }
  };

  const checkMetaMaskNetwork = async (value) => {
    const web3 = window.web3;
    const networkId = await web3.eth.net.getId();
    console.log("Network Id", networkId);
    if (value == "ether" && networkId == etherNetID) {
      // network is connected on Ropsten or Ether network.
      setMetamaskNetStatus("ether");
    } else if (value == "bnb" && networkId == binanceNetID) {
      setMetamaskNetStatus("bnb");
    } else if (value == "polygon" && networkId == polygonNetID) {
      setMetamaskNetStatus("polygon");
    } else {
      window.alert(
        "Please change the network into " +
        value +
        ". Once you changed the network in metamask refresh the page."
      );
      setMetamaskNetStatus("");
    }
  };

  const startDeployContractProcess = async () => {
    let tempNetwork = "";
    let token = null;
    // switch (props.tokenDetails.data.crypto_token.network_type) {
    //   case "Ethereum":
    //     checkMetaMaskNetwork("ether");
    //     token = EtherToken;
    //     tempNetwork = "ether";
    //     break;
    //   case "Binance":
    //     checkMetaMaskNetwork("bnb");
    //     token = BinanceToken;
    //     tempNetwork = "bnb";
    //     break;
    //   case "Polygon":
    //     checkMetaMaskNetwork("polygon");
    //     token = PolygonToken;
    //     tempNetwork = "polygon";
    //     break;
    //   default:
    //     token = null;
    //     break;
    // }
    if (token !== null && tempNetwork === metamaskNetStatus)
      deployContract(token, props.tokenDetails.data.crypto_token);
    else {
      console.log("Issue in generating token..");
      props.history.push(`/tokens/deploy-token/${token_unique}`);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const web3 = window.web3;
      let response;
      setPayMeButtonContent("Initied. Please Wait...");

      const networkId = await web3.eth.net.getId();

      // Send ether to admin account.
      if (metamaskNetStatus == "ether" && networkId == etherNetID) {
        let paymentAmount =
          etherAmount * Number(props.paymentInfo.data.token_creation_charge);
        paymentAmount = paymentAmount.toString();
        response = await web3.eth.sendTransaction({
          from: account,
          to: "0x57df1D419DF8fF508e2979bA551977487a064608",
          value: window.web3.utils.toWei(paymentAmount, "Ether"),
          gasLimit: 21000,
          gasPrice: 20000000000,
        });
      } else if (metamaskNetStatus == "bnb" && networkId == binanceNetID) {
        let paymentAmount =
          bnbAmount * Number(props.paymentInfo.data.token_creation_charge);
        paymentAmount = paymentAmount.toString();
        response = await web3.eth.sendTransaction({
          from: account,
          to: "0x12B260c8aE793c8D2729cEc3b04247A52b53963F",
          value: window.web3.utils.toWei(paymentAmount, "Ether"),
          gasLimit: 21000,
          gasPrice: 20000000000,
        });
      } else if (metamaskNetStatus == "polygon" && networkId == polygonNetID) {
        let paymentAmount =
          polygonAmount * Number(props.paymentInfo.data.token_creation_charge);
        paymentAmount = paymentAmount.toString();
        response = await web3.eth.sendTransaction({
          from: account,
          to: "0x12B260c8aE793c8D2729cEc3b04247A52b53963F",
          value: window.web3.utils.toWei(paymentAmount, "Ether"),
          gasLimit: 21000,
          gasPrice: 20000000000,
        });
        console.log("response", response);
      } else {
        window.alert(
          "Please change the network into " +
          metamaskNetStatus +
          ". Once you changed the network in metamask refresh the page."
        );
      }
      if (response !== null) {
        // Save the transaction details.
        console.log("Payment received..");
        props.dispatch(
          saveTokenPaymentAutoStart({
            crypto_token_id:
              props.tokenDetails.data.crypto_token.crypto_token_id,
            from_wallet_address: account,
            payment_id: response.transactionHash,
            payment_mode: metamaskNetStatus,
            payment_type: "auto",
          })
        );
        setPaymentCompleted(true);
        setDeployContractStatus(true);
        // startDeployContractProcess();
        // props.history.push(`/tokens/deploy-token/${token_unique}`);
        setPayMeButtonContent("");
      } else {
        const notificationMessage = getErrorNotificationMessage(
          "Payment Failed. Please try again..."
        );
        props.dispatch(createNotification(notificationMessage));
        console.log("Payment is not done..");
        setPayMeButtonContent("");
      }
    } catch (error) {
      const notificationMessage = getErrorNotificationMessage(
        "Payment Failed. Please try again..."
      );
      props.dispatch(createNotification(notificationMessage));
      console.log("Payment is not done..", error);
      setPayMeButtonContent("");
    }
  };

  const createTokenschema = Yup.object().shape({
    transactionHash: Yup.string().required("Transaction Hash is required"),
  });

  const manualTransaction = (values) => {
    props.dispatch(
      saveTokenPaymentAutoStart({
        crypto_token_id: props.tokenDetails.data.crypto_token.crypto_token_id,
        payment_id: values.transactionHash,
        payment_mode: manualTransNetType,
        payment_type: "manual",
      })
    );
  };

  return (
    <>
      <div
        className="content-wrapper min-heigth-100vh transaction-table"
        id="token-confirmation"
      >
        <section className="content-header">
          <h1>Create Token Confirmation</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">
                <i className="fa fa-dashboard"></i> Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="#">Tokens</a>
            </li>
            <li className="breadcrumb-item active">
              Create Token Confirmation
            </li>
          </ol>
        </section>
        {props.tokenDetails.loading ? (
          "Loading..."
        ) : props.tokenDetails.data.crypto_token.payment_status == "1" ? (
          <>
            Payment is completed we are redirecting to deploy contract page..
            <Redirect
              to={`/tokens/deploy-token/${props.tokenDetails.data.crypto_token.crypto_token_unique_id}`}
            />
          </>
        ) : (
          <section>
            <div className="container">
              <div className="custom-box p-3 p-lg-4 create-token-width custom-shadow">
                <div className="row no-gutters">
                  <div className="col-md-6">
                    <div class="container">
                      <div class="row justify-content-center">
                        <div style={{ color: "lightgreen", marginBottom: "25px" }}>
                          NOTE: Please select the Appropriate Wallet address to make the payment and Update your transaction Hash Below for our team to start the token deployment
                        </div>

                        <div class="col-lg-12">
                          <ul class="nav tab-nav tab-nav-s2 tab-nav-center mb-5">
                            <li>
                              <a
                                class="active"
                                data-toggle="tab"
                                href="#tab-eth"
                              >
                                Ethereum
                              </a>
                            </li>
                            <li>
                              <a data-toggle="tab" href="#tab-btc" class="">
                                BTC
                              </a>
                            </li>
                            <li>
                              <a data-toggle="tab" href="#tab-ripple" class="">
                                Ripple
                              </a>
                            </li>

                            <li>
                              <a data-toggle="tab" href="#tab-usdc" class="">
                                USDC
                              </a>
                            </li>
                            <li>
                              <a data-toggle="tab" href="#tab-usdt" class="">
                                USDT
                              </a>
                            </li>

                            <li>
                              <a data-toggle="tab" href="#tab-xlm" class="">
                                XLM
                              </a>
                            </li>

                            <li>
                              <a data-toggle="tab" href="#tab-tron" class="">
                                TRON
                              </a>
                            </li>
                            {/* <li>
                              <a data-toggle="tab" href="#tab-bnb" class="">
                                BNB
                              </a>
                            </li>
                            <li>
                              <a data-toggle="tab" href="#tab-polygon" class="">
                                Polygon
                              </a>
                            </li> */}
                          </ul>
                          {props.paymentInfo.loading ? (
                            "Loading..."
                          ) : (
                            // ETH STARt
                            <div class="tab-content">
                              <div
                                class="tab-pane fade active show"
                                id="tab-eth"
                              >
                                <div className="d-flex justify-content-center align-items-center flex-column">
                                  <h3 class="title title-md">
                                    Etherum Wallet Address
                                  </h3>
                                  <h6 className="black-text">
                                    {
                                      props.paymentInfo.data
                                        .ether_wallet_address
                                    }
                                  </h6>
                                  <div>
                                    <img
                                      src={
                                        props.paymentInfo.data
                                          .ether_wallet_qr_code
                                      }
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>

                              {/* ETHERUM END */}

                              {/* Ribble Start */}
                              <div class="tab-pane fade" id="tab-ripple">
                                <div className="d-flex justify-content-center align-items-center flex-column">
                                  <h3 class="title title-md">
                                    Ripple Wallet Address
                                  </h3>
                                  <h6 className="black-text">
                                    {
                                      props.paymentInfo.data
                                        .ripple_wallet_address
                                    }
                                  </h6>
                                  <div>
                                    <img
                                      src={
                                        props.paymentInfo.data
                                          .ripple_wallet_qr_code
                                      }
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>

                              {/* Ribble END */}

                              {/* USDC Start */}
                              <div class="tab-pane fade" id="tab-usdc">
                                <div className="d-flex justify-content-center align-items-center flex-column">
                                  <h3 class="title title-md">
                                    USDC Wallet Address
                                  </h3>
                                  <h6 className="black-text">
                                    {props.paymentInfo.data.usdc_wallet_address}
                                  </h6>
                                  <div>
                                    <img
                                      src={
                                        props.paymentInfo.data
                                          .usdc_wallet_qr_code
                                      }
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>

                              {/* USDC END */}

                              {/* USDT Start */}
                              <div class="tab-pane fade" id="tab-usdt">
                                <div className="d-flex justify-content-center align-items-center flex-column">
                                  <h3 class="title title-md">
                                    USDT Wallet Address
                                  </h3>
                                  <h6 className="black-text">
                                    {props.paymentInfo.data.usdt_wallet_address}
                                  </h6>
                                  <div>
                                    <img
                                      src={
                                        props.paymentInfo.data
                                          .usdt_wallet_qr_code
                                      }
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                              {/* USDC END */}

                              {/* XLM Start */}
                              <div class="tab-pane fade" id="tab-xlm">
                                <div className="d-flex justify-content-center align-items-center flex-column">
                                  <h3 class="title title-md">
                                    XLM Wallet Address
                                  </h3>
                                  <h6 className="black-text">
                                    {props.paymentInfo.data.xlm_wallet_address}
                                  </h6>
                                  <div>
                                    <img
                                      src={
                                        props.paymentInfo.data
                                          .xlm_wallet_qr_code
                                      }
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                              {/* XLM END */}

                              {/* TRON Start */}
                              <div class="tab-pane fade" id="tab-tron">
                                <div className="d-flex justify-content-center align-items-center flex-column">
                                  <h3 class="title title-md">
                                    TRON Wallet Address
                                  </h3>
                                  <h6 className="black-text">
                                    {props.paymentInfo.data.tron_wallet_address}
                                  </h6>
                                  <div>
                                    <img
                                      src={
                                        props.paymentInfo.data
                                          .tron_wallet_qr_code
                                      }
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                              {/* TRON END */}

                              {/* BNB Start */}
                              <div class="tab-pane fade" id="tab-bnb">
                                <div className="d-flex justify-content-center align-items-center flex-column">
                                  <h3 class="title title-md">
                                    BNB Wallet Address
                                  </h3>
                                  <h6 className="black-text">
                                    {
                                      props.paymentInfo.data
                                        .binance_wallet_address
                                    }
                                  </h6>
                                  <div>
                                    <img
                                      src={
                                        props.paymentInfo.data
                                          .binance_wallet_qr_code
                                      }
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                              {/* BNB END */}

                              {/* POLYGON START END */}
                              <div class="tab-pane fade" id="tab-polygon">
                                <div className="d-flex justify-content-center align-items-center flex-column">
                                  <h3 class="title title-md">
                                    Polygon Wallet Address
                                  </h3>
                                  <h6 className="black-text">
                                    {
                                      props.paymentInfo.data
                                        .polygon_wallet_address
                                    }
                                  </h6>
                                  <div>
                                    <img
                                      src={
                                        props.paymentInfo.data
                                          .polygon_wallet_qr_code
                                      }
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                              {/* POLYGOn END */}

                              {/* BTC START */}
                              <div class="tab-pane fade" id="tab-btc">
                                <div className="d-flex justify-content-center align-items-center flex-column">
                                  <h3 class="title title-md">
                                    BTC Wallet Address
                                  </h3>
                                  <h6 className="black-text">
                                    {
                                      props.paymentInfo.data
                                        .bitcoin_wallet_address
                                    }
                                  </h6>
                                  <div>
                                    <img
                                      src={
                                        props.paymentInfo.data
                                          .bitcoin_wallet_qr_code
                                      }
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                              {/* BTC END */}
                            </div>
                          )}
                        </div>
                      </div>
                      <Formik
                        initialValues={{
                          transactionHash: "",
                        }}
                        className="w-100"
                        validationSchema={createTokenschema}
                        onSubmit={(values) => manualTransaction(values)}
                      >
                        {({ errors, touched, setFieldValue }) => (
                          <Form noValidate>
                            <div className="container-fluid">
                              <div className="custom-box p-3 create-token-width">
                                <div className="row">
                                  <div className="col-lg-12 p-0">
                                    <div className="custom-box p-0 create-token-width">
                                      <div className="createToken-header">
                                        <h3 className="text-bold m-0 whitecolor">
                                          Enter the transactionHash
                                        </h3>
                                      </div>
                                      <br />
                                      <div>
                                        <h4>
                                          {" "}
                                          <span className="whitecolor text-bold">
                                            Pay Amount
                                          </span>{" "}
                                          :{" "}
                                          {
                                            props.paymentInfo.data
                                              .token_creation_charge_with_gas_fee
                                          }{" "}
                                          USD
                                        </h4>
                                      </div>
                                      {/* <RadioGroup
                                        aria-label="coin"
                                        name="coin"
                                        row
                                        value={manualTransNetType}
                                        onChange={(event) =>
                                          setManualTransNetType(
                                            event.target.value
                                          )
                                        }
                                      >
                                        <FormControlLabel
                                          value="ether"
                                          control={<Radio color="primary" />}
                                          label="Ethereum"
                                        />

                                        <FormControlLabel
                                          value="btc"
                                          control={<Radio color="primary" />}
                                          label="Bitcoin"
                                        />
                                        <FormControlLabel
                                          value="ripple"
                                          control={<Radio color="primary" />}
                                          label="Ripple"
                                        />
                                        <FormControlLabel
                                          value="usdc"
                                          control={<Radio color="primary" />}
                                          label="USDC"
                                        />
                                        <FormControlLabel
                                          value="usdt"
                                          control={<Radio color="primary" />}
                                          label="USDT"
                                        />
                                        <FormControlLabel
                                          value="xlm"
                                          control={<Radio color="primary" />}
                                          label="XLM"
                                        />
                                        <FormControlLabel
                                          value="tron"
                                          control={<Radio color="primary" />}
                                          label="TRON"
                                        />

                                        {/* <FormControlLabel
                                          value="polygon"
                                          control={<Radio color="primary" />}
                                          label="Polygon"
                                        />
                                        <FormControlLabel
                                          value="bnb"
                                          control={<Radio color="primary" />}
                                          label="BNB"
                                        /> 
                                      </RadioGroup> */}
                                      <div className="form-wrapper p-3">
                                        <div className="row">
                                          <div class="form-group col-lg-12">
                                            <h5>
                                              Transaction Hash{" "}
                                              <span class="text-danger">*</span>
                                            </h5>
                                            <div class="controls">
                                              <Field
                                                type="text"
                                                name="transactionHash"
                                                placeholder="Transaction Hash"
                                                className={`no-padding form-control ${touched.transactionHash &&
                                                  errors.transactionHash
                                                  ? "is-invalid"
                                                  : ""
                                                  }`}
                                              />
                                              <ErrorMessage
                                                component="div"
                                                name="transactionHash"
                                                className="invalid-feedback mt-3"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-12 p-0">
                                        <div className="d-flex justify-content-center my-3">
                                          <button
                                            type="submit"
                                            className="btn btn-primary btn-medium withTheme ml-sm-3 mt-3 mt-md-0"
                                            disabled={
                                              props.saveTokenPayAuto
                                                .buttonDisable
                                            }
                                          >
                                            {props.saveTokenPayAuto
                                              .loadingButtonContent !== null
                                              ? props.saveTokenPayAuto
                                                .loadingButtonContent
                                              : "Submit"}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>

                  <div className="col-md-6 align-items-center d-flex justify-content-center">

                    {props.paymentInfo.loading ? (
                      "Loading..."
                    ) : (
                      <div className="admin-amount-wrapper">
                        <div style={{ color: "lightgreen" }}>
                          NOTE: Please connect your Metamask with the appropriate network and make the payment to complete your token generation
                        </div>
                        <br /><br />
                        <div>
                          <h4>
                            {" "}
                            <span className="whitecolor text-bold">
                              Pay Amount
                            </span>{" "}
                            : {props.paymentInfo.data.token_creation_charge} USD
                          </h4>

                          <h5 className="black-text">
                            {loading ? (
                              ""
                            ) : (
                              <>
                                Balance :{" "}
                                {window.web3.utils.fromWei(ethBalance, "Ether")}{" "}
                                {crytoSymbol}
                              </>
                            )}
                          </h5>
                        </div>
                        <p className="text-bold my-4 whitecolor">
                          <span className="invalid-feedback d-inline">
                            Note :
                          </span>{" "}
                          We accept ETH,BNB,Polygon,BTC.
                        </p>
                        <ul className="list list-check list-check-s3 list-check-s3-sm w-100 my-4">
                          <li className="text-bold mb-3">
                            <p className="whitecolor">
                              Ether :{" "}
                              {etherAmount *
                                Number(
                                  props.paymentInfo.data.token_creation_charge
                                )}{" "}
                              Ether <i class="fab fa-ethereum"></i>
                            </p>
                          </li>
                          <li className="text-bold mb-3">
                            <p className="whitecolor">
                              Polygon :{" "}
                              {polygonAmount *
                                Number(
                                  props.paymentInfo.data.token_creation_charge
                                )}{" "}
                              Matic <i class="fab fa-ethereum"></i>
                            </p>
                          </li>
                          <li className="text-bold mb-3">
                            <p className="whitecolor">
                              Binance :{" "}
                              {bnbAmount *
                                Number(
                                  props.paymentInfo.data.token_creation_charge
                                )}{" "}
                              BNB <i class="fab fa-bitcoin"></i>
                            </p>
                          </li>
                        </ul>
                        <RadioGroup
                          aria-label="coin"
                          name="coin"
                          row
                          value={metamaskNetStatus}
                          onChange={(event) =>
                            checkMetaMaskNetwork(event.target.value)
                          }
                        >
                          <FormControlLabel
                            value="ether"
                            control={<Radio color="primary" />}
                            label="Ethereum"
                          />
                          <FormControlLabel
                            value="polygon"
                            control={<Radio color="primary" />}
                            label="Polygon"
                          />
                          <FormControlLabel
                            value="bnb"
                            control={<Radio color="primary" />}
                            label="BNB"
                          />
                        </RadioGroup>

                        <div className="my-3 my-lg-4">
                          {paymentCompleted === false &&
                            deployContractStatus === false ? (
                            <button
                              type="submit"
                              className="btn btn-md btn-medium btn-grad no-change p-3 mx-auto d-block"
                              onClick={handleSubmit}
                              disabled={
                                payMeButtonContent !== "" ? true : false
                              }
                            >
                              {payMeButtonContent !== ""
                                ? payMeButtonContent
                                : "Click here to pay"}
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-md btn-medium btn-grad no-change p-3 mx-auto d-block"
                              onClick={startDeployContractProcess}
                              disabled={
                                deployContractButtonContent !== ""
                                  ? true
                                  : false
                              }
                            >
                              {deployContractButtonContent !== ""
                                ? deployContractButtonContent
                                : "Deploy Contract"}
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  tokenDetails: state.token.tokenDetails,
  paymentInfo: state.token.paymentInfo,
  saveTokenPayAuto: state.token.saveTokenPayAuto,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(CreateTokenConfirmation);
