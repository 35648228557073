import React,{useState} from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import VerificationCodeInput from "./VerificationCodeInput";
import {
  registerVerifyResendStart,
  registerVerifyStart,
} from "../store/actions/UserAction";
import { connect } from "react-redux";

const VerificationCodeModal = (props) => {

  const [loading, setLoading] = useState(false);
  const [inputData, setInputData] = useState({});

  const emailId = localStorage.getItem("emailId");

  const submitVerfication = (event) => {
    event.preventDefault();
    if(inputData.verification_code){
      props.dispatch(registerVerifyStart({verification_code : inputData.verification_code , email : emailId, route : props.route}));
    }
  };

  const resendVerfication = (event) => {
    event.preventDefault();
    props.dispatch(registerVerifyResendStart({email : emailId}));
  };

  return (
    <>
      <Modal
        className="nft-verification-code"
        show={props.show}
      >
        <Modal.Header className="justify-content-center">
          <Modal.Title className="popup-nft">
            Enter verification code
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="verificationCodeWrapper">
            <div className="inputsWrapper">
              <p className="c-email-content-text text-italic opacity-30 text-title mb-3">
                For verification code, please check your email inbox
              </p>
              <VerificationCodeInput
                length={6}
                label={"Enter the verification code"}
                loading={loading}
                labelClassName="mb-3 mx-auto"
                onComplete={(code) => {
                  setLoading(true);
                  setTimeout(() => {
                    setInputData({
                      ...inputData,
                      verification_code: code,
                    });
                  }, 1000);
                  setTimeout(() => {
                    setLoading(false);
                    setInputData({
                      ...inputData,
                      verification_code: code,
                    });
                  }, 10000);
                }}
              />
              <div className="verify-btn-sec mt-4 text-center">
                <Button
                  className="btn btn-md btn-medium btn-grad no-change p-3"
                  onClick={submitVerfication}
                  disabled={inputData.verification_code ? false : true}
                >
                  Verify
                </Button>
              </div>
              <div className="d-flex mt-4 footerLinks">
                <p className="c-email-content-text text-italic opacity-30 text-title mb-0">
                Haven't received the code?
                </p>
                <button type="button" onClick={resendVerfication} className="link-btn p-3 mt-0">
                  Resend Code
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  resend: state.users.registerVerifyResend,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(VerificationCodeModal);
