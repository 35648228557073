import React, { useState, useEffect,useCallback } from "react";
import { Link } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {useDropzone} from 'react-dropzone'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import AdditionalInfo from "../transactions/AdditionalInfo";
import configuration from "react-global-configuration";
import { connect } from "react-redux";
import { fetchSingleTokenStart } from "../store/actions/TokenAction";
import { useParams } from "react-router";
// import "./SingleToken.css"
import moment from "moment";



const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    top: "8px",
    color: "#fff",
    fontSize: "1em",
    padding: "0.75em",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow placement="top" classes={classes} {...props} />;
}

const useTableStyles = makeStyles({
  table: {
    backgroundColor: "#171717",
    color: "#828282",
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: "#171717",
      color: "#828282",
      borderBottom: "1.5px solid #828282",
      minWidth: "150px",
      maxWidth: "200px",
    },
    "& .MuiTableCell-body": {
      color: "#828282",
      borderBottom: "1.5px solid #828282",
      maxWidth: "200px",
      overflow: "hidden",
    },
  },
  MuiTablePagination: {
    backgroundColor: "red",
  },
});

const usepaginationStyle = makeStyles({
  MuiTablePagination: {
    backgroundColor: "#171717",
    color: "#828282",
    "& .MuiIconButton-root.Mui-disabled": {
      color: "#828282",
      cursor: "not-allowed",
      pointerEvents: "auto",
    },
  },
});

const useButtonStyle = makeStyles({
  filterButton: {
    backgroundColor: "transperant",
    color: "#828282",
    borderColor: "#828282",
    zIndex: 1,
    fontWeight: 500,
  },
});

const SingleToken = (props) => {
const [list, setList] = useState([]);
    const [item, setItem] = useState("");
    const handleDelete = index => {
        let test = [...list];
        test.splice(index, 1);
		setList(test);
	};
	const handleSubmit = () => {
        if (item === "") return alert('The input value is empty');
		setList([...list,item]);
		setItem('');
	}
    const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
  const [files, setFiles] = useState([])
  const { token_unique } = useParams();

  const tableclasses = useTableStyles();

  const buttonStyle = useButtonStyle();

  const paginationStyle = usepaginationStyle();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [transactionDetails, setTransactionDetails] = useState("");

  const [transactionLoading, setTransactionLoading] = useState(false);

  const etherScanAPIKey = configuration.get("configData.ether_api_key");

  useEffect(() => {
    props.dispatch(
      fetchSingleTokenStart({ crypto_token_unique_id: token_unique })
    );
  }, []);

  useEffect(() => {
    if (!props.tokenDetails.loading) {
      if (props.tokenDetails.data.crypto_token.deploy_status == 1) {
        setTransactionLoading(true);
        getTransactionDetails(
          props.tokenDetails.data.crypto_token.contract_address
        );
      }
    }
  }, [props.tokenDetails.data]);

  const getTransactionDetails = (contract_address) => {
    fetch(
      "https://api-testnet.polygonscan.com/api?module=account&action=tokentx&contractaddress=" +
        contract_address +
        "&page=1&offset=100&sort=asc&apikey=" +
        etherScanAPIKey
    )
      .then((response) => response.json())
      .then((data) => setTransactionDetails(data))
      .then(() => setTransactionLoading(false));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <div className="content-wrapper min-heigth-100vh">
        <section className="content-header">
          <h1>ICO Details</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">
                <i className="fa fa-dashboard"></i> Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="#">Initial Coin Offering</a>
            </li>
            <li className="breadcrumb-item active">ICO Details</li>
          </ol>
        </section>

        <section className="content w-100">
          <div className="row">
            <div className="col-12">
              <div className="custom-box w-100 m-0">
                {/* {!props.tokenDetails.loading && (
                  <div className="box-header with-border">
                    <a href="#">
                      <img
                        src={props.tokenDetails.data.crypto_token.picture}
                        alt=""
                      />
                    </a>
                    <h3 className="box-title">
                      {props.tokenDetails.data.crypto_token.name}
                      <small className="subtitle mt-0">
                        {props.tokenDetails.data.crypto_token.description}
                      </small>
                    </h3>
                  </div>
                )} */}
                <div className="row no-gutters align-items-center">
                  {props.tokenDetails.loading ? (
                    "Loading...."
                  ) : (
                    <>
                      <div className="col-lg-6">
                          <div className="nft-layer-create">
                              <ul>
                {list.map((listItem, index) => (
                    <ListItem
                        item={listItem}
                        index={index}
                        onDelete={item => handleDelete(item)}
                    />
                ))}
            </ul>
            <input
                              type="text"
                              placeholder="New Layer"
				value={item}
                onChange={e => setItem(e.target.value)}
            />
            <button type="button" className="nft-layer-add-btn" onClick={handleSubmit}>
             <FontAwesomeIcon icon={faPlus} />
            </button>
                      </div>
                      </div>
                        <div className="col-lg-6">
                          <div className="nft-layer-create-right">
                             <div {...getRootProps()}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <p>Click or drop images here!</p>
      }
    </div>
                          </div>
                   
                      </div>
                      {/* <div className="col-lg-4 ">
                        <div className="secondry-custom-box mt-4">
                          <div className="">
                            <h3 className="mb-0 whitecolor text-bold">
                              Extra Features
                            </h3>
                            <hr className="custom-hr" />
                          </div>
                          <div className="single-token-details-wrapper">
                            <div className="row">
                              <div className="col-6 col-md-6">
                                <h5 className="mb-0 text-bold whitecolor">
                                  Remove Copyright
                                </h5>
                              </div>
                              <div className="col-2 col-md-2">
                                <h5 className="mb-0">:</h5>
                              </div>
                              <div className="col-4 col-md-4">
                                <h5 className="mb-0 text-right">Enabled</h5>
                              </div>
                            </div>
                          </div>
                          <div className="single-token-details-wrapper">
                            <div className="row">
                              <div className="col-6 col-md-6">
                                <h5 className="mb-0 text-bold whitecolor">
                                  Burnable
                                </h5>
                              </div>
                              <div className="col-2 col-md-2">
                                <h5 className="mb-0">:</h5>
                              </div>
                              <div className="col-4 col-md-4">
                                <h5 className="mb-0 text-right">Disabled</h5>
                              </div>
                            </div>
                          </div>
                          <div className="single-token-details-wrapper">
                            <div className="row">
                              <div className="col-6 col-md-6">
                                <h5 className="mb-0 text-bold whitecolor">
                                  Mintable
                                </h5>
                              </div>
                              <div className="col-2 col-md-2">
                                <h5 className="mb-0">:</h5>
                              </div>
                              <div className="col-4 col-md-4">
                                <h5 className="mb-0 text-right">Enabled</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </>
                  )}
                </div>
                {/* <div className="m-2">
                  <TablePagination
                    rowsPerPageOptions={[
                      10,
                      15,
                      20,
                      { value: -1, label: "All" },
                    ]}
                    component="div"
                    count={
                      transactionDetails && transactionDetails.result.length
                    }
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    className={paginationStyle.MuiTablePagination}
                    showFirstButton="true"
                    showLastButton="true"
                  />
                  {transactionLoading ? (
                    <div className="table-nodata">
                      <h5 className="text">Loading</h5>
                    </div>
                  ) : transactionDetails &&
                    transactionDetails.result.length > 0 &&
                    transactionDetails.status != "0" ? (
                    <TableContainer component={Paper}>
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        className={tableclasses.table}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell className="transaction-eye-width"></TableCell>
                            <TableCell>
                              <span className="text-bold">Txn Hash</span>
                            </TableCell>
                            <TableCell>
                              <span className="text-bold">Token</span>
                            </TableCell>
                            <TableCell>
                              <BootstrapTooltip title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,">
                                <span className="text-bold">
                                  Token Symbol
                                  <i class="far fa-question-circle ml-2 "></i>
                                </span>
                              </BootstrapTooltip>
                            </TableCell>
                            <TableCell>
                              <span className="text-bold">Block</span>
                            </TableCell>
                            <TableCell>
                              <span className="text-bold">Age</span>
                            </TableCell>
                            <TableCell>
                              <span className="text-bold">From</span>
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                              <span className="text-bold">To</span>
                            </TableCell>
                            <TableCell>
                              <span className="text-bold">Value</span>
                            </TableCell>
                            <TableCell>
                              <BootstrapTooltip title="(Gas price * Gas used by Txns) in ether">
                                <span className="ml-2 text-bold">
                                  Txn Fee{" "}
                                  <i class="far fa-question-circle ml-2 "></i>
                                </span>
                              </BootstrapTooltip>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {transactionDetails.result
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((data, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <div>
                                    <AdditionalInfo
                                      tokenInfo={props.tokenDetails.data}
                                      transactionData={data}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <p className="text-no-overflow mb-0">
                                    {" "}
                                    {data.hash}
                                  </p>
                                </TableCell>
                                <TableCell>
                                  <p className="text-no-overflow mb-0">
                                    {" "}
                                    {data.tokenName}
                                  </p>
                                </TableCell>
                                <TableCell>
                                  <p className="mb-0"> {data.tokenSymbol}</p>
                                </TableCell>
                                <TableCell>
                                  <p className="text-no-overflow mb-0 ">
                                    {" "}
                                    {data.blockNumber}
                                  </p>
                                </TableCell>
                                <TableCell>
                                  <BootstrapTooltip
                                    title={moment(
                                      Number(data.timeStamp)
                                    ).format("DD-MM-YYYY h:mm:ss")}
                                  >
                                    <p className="text-no-overflow mb-0">
                                      {moment(Number(data.timeStamp)).format(
                                        "DD-MM-YYYY h:mm:ss"
                                      )}
                                    </p>
                                  </BootstrapTooltip>
                                </TableCell>
                                <TableCell>
                                  <BootstrapTooltip title={data.from}>
                                    <p className="mb-0 text-primary text-no-overflow">
                                      {" "}
                                      {data.from}
                                    </p>
                                  </BootstrapTooltip>
                                </TableCell>
                                <TableCell className="text-center">
                                  <span className="right-arrow-trans">
                                    <i class="fas fa-arrow-right text-success"></i>
                                  </span>
                                </TableCell>
                                <TableCell>
                                  <BootstrapTooltip title={data.to}>
                                    <p className="mb-0 text-primary text-no-overflow">
                                      {" "}
                                      {data.to}
                                    </p>
                                  </BootstrapTooltip>
                                </TableCell>
                                <TableCell>
                                  <p className="mb-0 text-primary text-no-overflow">
                                    {" "}
                                    {data.value}
                                  </p>
                                </TableCell>
                                <TableCell>
                                  <p className="mb-0 text-primary text-no-overflow">
                                    {" "}
                                    {data.gas}
                                  </p>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <div className="table-nodata">
                      <h5 className="text">No Transactions Found</h5>
                    </div>
                  )}
                  <TablePagination
                    rowsPerPageOptions={[
                      10,
                      15,
                      20,
                      { value: -1, label: "All" },
                    ]}
                    component="div"
                    count={
                      transactionDetails && transactionDetails.result.length
                    }
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    className={paginationStyle.MuiTablePagination}
                    showFirstButton="true"
                    showLastButton="true"
                  />
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
const ListItem = ({item, index, onDelete}) => {
    return (
        <li>
            {item}
            <button onClick={() => onDelete(index)}>Delete</button>
        </li>
    );
};

const mapStateToPros = (state) => ({
  tokenDetails: state.token.tokenDetails,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SingleToken);
