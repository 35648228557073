import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import { Notify } from "react-redux-notify";
import FooterIndex from "./Footer/FooterIndex";
import { connect } from "react-redux";
import Authheader from "./Header/Authheader";
import { Helmet } from "react-helmet";
import AuthFooter from "./Footer/AuthFooter";
import Login from "../auth/Login";
import Register from "../auth/Register";
import ResetPassword from "../auth/ResetPassword";
import RandomOnlinePeople from "../Helper/RandomOnlinePeople";
import FbChat from "../Helper/FbChat";

const EmptyLayout = (props) => {
  return (
    <>
      <Notify position="TopRight" />
      <Authheader {...props}/>
      <Helmet>
        <script
            type="text/javascript"
            src={window.location.origin + "/assets/js/jquery.bundle.js"}
          ></script>
        <script
          type="text/javascript"
          src={window.location.origin + "/assets/js/scripts.js"}
        ></script>
        <script
          type="text/javascript"
          src={window.location.origin + "/assets/js/charts.js"}
        ></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/waypoints/2.0.5/waypoints.min.js"></script>
        <link
          rel="stylesheet"
          type="text/css"
          href={window.location.origin + "/assets/css/landingstyle.css"}
          id="changeTheme"
        ></link>
      </Helmet>
      {React.cloneElement(props.children)}
      {/* <AuthFooter /> */}
      {/* <Login /> */}
      {/* <Register /> */}
      <ResetPassword />
      {/* <RandomOnlinePeople /> */}
      {/* <FbChat/> */}
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(EmptyLayout);
