import {
  FETCH_LIST_OF_NFT_START,
  FETCH_LIST_OF_NFT_SUCCESS,
  FETCH_LIST_OF_NFT_FAILURE,
  FETCH_SINGLE_NFT_IMAGE_START,
  FETCH_SINGLE_NFT_IMAGE_SUCCESS,
  FETCH_SINGLE_NFT_IMAGE_FAILURE,
  FETCH_SINGLE_NFT_PROPERTIES_START,
  FETCH_SINGLE_NFT_PROPERTIES_SUCCESS,
  FETCH_SINGLE_NFT_PROPERTIES_FAILURE,
  FETCH_LIST_NFT_PROPERTIES_START,
  FETCH_LIST_NFT_PROPERTIES_SUCCESS,
  FETCH_LIST_NFT_PROPERTIES_FAILURE,
  DELETE_SINGLE_NFT_START,
  DELETE_SINGLE_NFT_SUCCESS,
  DELETE_SINGLE_NFT_FAILURE,
  UPLOAD_NFT_FILES_START,
  UPLOAD_NFT_FILES_SUCCESS,
  UPLOAD_NFT_FILES_FAILURE,
  SEARCH_NFT_IMAGE_START,
  SEARCH_NFT_IMAGE_SUCCESS,
  SEARCH_NFT_IMAGE_FAILURE,
  PAGINATE_LIST_OF_NFT_START,
  SORT_LIST_OF_NFT_START,
  SORT_LIST_OF_NFT_SUCCESS,
  SORT_LIST_OF_NFT_FAILURE,
  EDIT_NFT_DETAILS,
  UPDATE_NFT_DETAILS_START,
  UPDATE_NFT_DETAILS_SUCCESS,
  UPDATE_NFT_DETAILS_FAILURE,
  ADD_NFT_IMAGE_START,
  ADD_NFT_IMAGE_SUCCESS,
  ADD_NFT_IMAGE_FAILURE,
  FETCH_NFT_IMAGES_LIST_AFTER_DELETE,
  UPDATE_NFT_MINT_STATUS_START,
  UPDATE_NFT_MINT_STATUS_SUCCESS,
  UPDATE_NFT_MINT_STATUS_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  listOFNFT: {
    data: [],
    loading: false,
    error: false,
    total: 0,
  },
  singleNFT: {
    data: {},
    loading: false,
    error: false,
  },
  singleNFTPropeties: {
    data: {},
    loading: false,
    error: false,
  },
  listOfNFTProperties: {
    data: {},
    loading: false,
    error: false,
  },
  deleteSingleNFT: {
    data: {},
    loading: false,
    error: false,
    buttonDisabled: false,
    loadingButttonContent: null,
  },
  uploadNFTFiles: {
    data: {},
    loading: false,
    error: false,
    buttonDisabled: false,
    loadingButttonContent: null,
  },
  editNFTInputdata: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  addNFTImage : {
    data: {},
    loading: false,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  updateMintStatus : {
    data: {},
    loading: false,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  }
};

const NFTReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LIST_OF_NFT_START:
      return {
        ...state,
        listOFNFT: {
          ...state.listOFNFT,
          loading: true,
          error: false,
          total: 0,
        },
      };
    case FETCH_LIST_OF_NFT_SUCCESS:
      return {
        ...state,
        listOFNFT: {
          ...state.listOFNFT,
          data: [...state.listOFNFT.data , ...action.data.nft_images],
          loading: false,
          error: false,
          total: action.data.total,
        },
      };
    case FETCH_LIST_OF_NFT_FAILURE:
      return {
        ...state,
        listOFNFT: {
          ...state.listOFNFT,
          loading: false,
          error: false,
          total: 0,
        },
      };

    case PAGINATE_LIST_OF_NFT_START:
      return {
        ...state,
        listOFNFT: {
          ...state.listOFNFT,
          loading: false,
          error: false,
        },
      };

    case FETCH_SINGLE_NFT_IMAGE_START:
      return {
        ...state,
        singleNFT: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_SINGLE_NFT_IMAGE_SUCCESS:
      return {
        ...state,
        singleNFT: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_SINGLE_NFT_IMAGE_FAILURE:
      return {
        ...state,
        singleNFT: {
          data: {},
          loading: false,
          error: false,
        },
      };

    case FETCH_SINGLE_NFT_PROPERTIES_START:
      return {
        ...state,
        singleNFTPropeties: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_SINGLE_NFT_PROPERTIES_SUCCESS:
      return {
        ...state,
        singleNFTPropeties: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_SINGLE_NFT_PROPERTIES_FAILURE:
      return {
        ...state,
        singleNFTPropeties: {
          data: {},
          loading: false,
          error: false,
        },
      };

    case FETCH_LIST_NFT_PROPERTIES_START:
      return {
        ...state,
        listOfNFTProperties: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_LIST_NFT_PROPERTIES_SUCCESS:
      return {
        ...state,
        listOfNFTProperties: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_LIST_NFT_PROPERTIES_FAILURE:
      return {
        ...state,
        listOfNFTProperties: {
          data: {},
          loading: false,
          error: false,
        },
      };

    case DELETE_SINGLE_NFT_START:
      return {
        ...state,
        deleteSingleNFT: {
          data: {},
          loading: true,
          error: false,
          buttonDisabled: true,
          loadingButttonContent: "Loading... Please wait",
        },
      };
    case DELETE_SINGLE_NFT_SUCCESS:
      return {
        ...state,
        deleteSingleNFT: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisabled: false,
          loadingButttonContent: null,
        },
      };
    case DELETE_SINGLE_NFT_FAILURE:
      return {
        ...state,
        deleteSingleNFT: {
          data: {},
          loading: false,
          error: action.data,
          buttonDisabled: false,
          loadingButttonContent: null,
        },
      };
      case FETCH_NFT_IMAGES_LIST_AFTER_DELETE :
        return {
          ...state,
          listOFNFT: {
            ...state.listOFNFT,
            data: action.data.nft_images,
            total : action.data.total
          },
        };

    case UPLOAD_NFT_FILES_START:
      return {
        ...state,
        uploadNFTFiles: {
          data: {},
          loading: true,
          error: false,
          buttonDisabled: true,
          loadingButttonContent: "Uploading... Please wait",
        },
      };
    case UPLOAD_NFT_FILES_SUCCESS:
      return {
        ...state,
        uploadNFTFiles: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisabled: false,
          loadingButttonContent: null,
        },
      };
    case UPLOAD_NFT_FILES_FAILURE:
      return {
        ...state,
        uploadNFTFiles: {
          data: {},
          loading: false,
          error: action.data,
          buttonDisabled: false,
          loadingButttonContent: null,
        },
      };

    case SEARCH_NFT_IMAGE_START:
      return {
        ...state,
        listOFNFT: {
          ...state.listOFNFT,
          loading: false,
          error: false,
        },
      };
    case SEARCH_NFT_IMAGE_SUCCESS:
      return {
        ...state,
        listOFNFT: {
          data: action.data.merge_images,
          loading: false,
          error: false,
          total: action.data.total,
        },
      };
    case SEARCH_NFT_IMAGE_FAILURE:
      return {
        ...state,
        listOFNFT: {
          ...state.listOFNFT,
          loading: false,
          error: false,
          total: 0
        },
      };

      case SORT_LIST_OF_NFT_START:
      return {
        ...state,
        listOFNFT: {
          ...state.listOFNFT,
          loading: true,
          error: false,
          total: 0,
        },
      };
    case SORT_LIST_OF_NFT_SUCCESS:
      return {
        ...state,
        listOFNFT: {
          data: [...action.data.nft_images],
          loading: false,
          error: false,
          total: action.data.total,
        },
      };
    case SORT_LIST_OF_NFT_FAILURE:
      return {
        ...state,
        listOFNFT: {
          ...state.listOFNFT,
          loading: false,
          error: false,
          total: 0,
        },
      };

      case EDIT_NFT_DETAILS:
      return {
        ...state,
        editNFTInputdata: {
          ...state.editNFTInputdata,
          loading: false,
          error: false,
          data: {
            ...state.editNFTInputdata.data,
            [action.name]: action.value,
          },
        },
      };

      case UPDATE_NFT_DETAILS_START:
      return {
        ...state,
        editNFTInputdata: {
          ...state.editNFTInputdata,
          data: {
            ...state.editNFTInputdata.data,
            ...action.data
          },
          buttonDisable: true,
          loadingButtonContent: "Loading...please wait",
        },
      };
    case UPDATE_NFT_DETAILS_SUCCESS:
      return {
        ...state,
        editNFTInputdata: {
          ...state.editNFTInputdata,
          data: {},
          buttonDisable: false,
          loadingButtonContent:null,
        },
      };
    case UPDATE_NFT_DETAILS_FAILURE:
      return {
        ...state,
        editNFTInputdata: {
          ...state.editNFTInputdata,
          data: {},
          buttonDisable: false,
          loadingButtonContent:null,
        },
      };
    case ADD_NFT_IMAGE_START:
      return {
        ...state,
        addNFTImage: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading...Please Wait",
        },
      };
    case ADD_NFT_IMAGE_SUCCESS:
      return {
        ...state,
        addNFTImage: {
          data: action.data.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case ADD_NFT_IMAGE_FAILURE:
      return {
        ...state,
        addNFTImage: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case UPDATE_NFT_MINT_STATUS_START:
      return {
        ...state,
        updateMintStatus: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading...Please Wait",
        },
      };
    case UPDATE_NFT_MINT_STATUS_SUCCESS:
      return {
        ...state,
        updateMintStatus: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case UPDATE_NFT_MINT_STATUS_FAILURE:
      return {
        ...state,
        updateMintStatus: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    default:
      return state;
  }
};

export default NFTReducer;
