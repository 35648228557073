import React from "react";
import { Link } from "react-router-dom";
import { Container, Form, Row, Col, Button, FormGroup } from "react-bootstrap";

const CreateNFTStep3 = () => {
  return (
    <>
      <div className="nft-properties-sec">
        <Container>
          <Form>
            <Form.Group
              className="mb-3 nft-properties-title"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>What kind of NFT your going to create</Form.Label>
              <Form.Control
                className="input-bordered properties-textarea"
                as="textarea"
                rows={3}
              />
            </Form.Group>
          </Form>
          <div className="nft-property-value">
            <h3>How many properties your going to add?</h3>
            <div className="no-property">
            <h1>Add Properties</h1>
            <p>No.of.Properties</p>
            </div>
            <Form>
              <Row>
                <Col>
                  <FormGroup>
                                       <Form.Control
                      type="text"
                      className="input-bordered text-value"
                      placeholder="type"
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Form.Control
                      type="text"
                      className="input-bordered text-value"
                      placeholder="Value"
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <Button className="btn btn-md btn-medium btn-grad no-change p-3">
                    Add
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
          <div className="nft-video-sec-btn">
            <Link
              to="/create-nft-step2"
              className="btn btn-md btn-medium btn-grad no-change p-3"
            >
              Previous
            </Link>
            <Link
              to="/create-nft-step4"
              className="btn btn-md btn-medium btn-grad no-change p-3"
            >
              Next
            </Link>
          </div>
        </Container>
      </div>
    </>
  );
};

export default CreateNFTStep3;
