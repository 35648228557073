import React, { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import { userLoginStart } from "../store/actions/UserAction";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import configuration from "react-global-configuration";
import VerificationCodeModal from "../Helper/VerificationCodeModal";
import { authContext } from "../auth/AuthProvider";
const $ = window.$;

const ConnectWallet = (props) => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleLogin = (values) => {
    props.dispatch(userLoginStart(values));
  };

  const { status, handleConnectWalletClose } = props;

  const { loginConnectors, handleConnector, activatingConnector } = useContext(authContext);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {

    if(props.login.code == "1001"){
      $("#connect-wallet-popup").modal("hide");
      handleShow(true);
    }

  }, [props.login.code]);

  return (
    <>
      <div className="modal fade" id="connect-wallet-popup">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
						<div class="headerwrapper">
							<h4 class="text-center text-capitalize primary-text">Connect Wallet</h4>
							<a
									href="#"
									className="modal-close"
									data-dismiss="modal"
									aria-label="Close"
								>
									<em className="ti ti-close"></em>
								</a>
							</div>
          	
							<div class="desclimer">
								<p class="mb-3">
                  By connecting a wallet, you agree to Xpad Terms of Service and acknowledge that you have read and understand the Xpad Protocol Disclaimer.
								</p>
							</div>
							<div class="wallet-content-box">
              {loginConnectors.map((connectors, index) => (
                  <>
                    {width < 576 ? (
                      <>
                        {connectors.name != "MetaMask" && (
                          <div
                            className={`wallet-content ${
                              activatingConnector != undefined
                                ? "connecting-wallet"
                                : ""
                            }`}
                            key={index}
                          >
                            <button
                              disabled={
                                activatingConnector != undefined ? true : false
                              }
                              onClick={() =>
                                handleConnector(connectors.connectorFunction)
                              }
                            >
                              <div className="wallet-metamask-card">
                                <div className="wallet-metamask-left-sec">
                                  <div className="wallet-metamask-img-sec">
                                    <img
                                      src={
                                        window.location.origin + connectors.logo
                                      }
                                      alt={connectors.name}
                                    />
                                  </div>
                                  <div className="wallet-metamask-info-sec">
                                    <h4>{connectors.name}</h4>
                                  </div>
                                </div>
                                <div className="wallet-metamask-right-sec">
                                  {connectors.is_popular && <p>Popular</p>}
                                  {JSON.stringify(activatingConnector) ===
                                    JSON.stringify(
                                      connectors.connectorFunction
                                    ) && <div id="spinner"></div>}
                                </div>
                              </div>
                            </button>
                          </div>
                        )}
                      </>
                    ) : (
                      <div
                        className={`wallet-content ${
                          activatingConnector != undefined
                            ? "connecting-wallet"
                            : ""
                        }`}
                        key={index}
                        // onClick={() =>
                        //   handleConnector(connectors.connectorFunction)
                        // }
                      >
                        <button
                          disabled={
                            activatingConnector != undefined
                              ? true
                              : false && !connectors.isAvailable
                          }
                          onClick={() =>
                            handleConnector(connectors.connectorFunction)
                          }
                        >
                          <div className="wallet-metamask-card">
                            <div className="wallet-metamask-left-sec">
                              <div className="wallet-metamask-img-sec">
                                <img
                                  src={window.location.origin + connectors.logo}
                                  alt={connectors.name}
                                />
                              </div>
                              <div className="wallet-metamask-info-sec">
                                <h4>{connectors.name}</h4>
                              </div>
                            </div>
                            <div className="wallet-metamask-right-sec">
                              {connectors.is_popular && <p>Popular</p>}
                              {JSON.stringify(activatingConnector) ===
                                JSON.stringify(
                                  connectors.connectorFunction
                                ) && <div id="spinner"></div>}
                            </div>
                          </div>
                        </button>
                      </div>
                    )}
                  </>
                ))}
								{/* <div class="wallet-content ">
									<button 
                    disabled={
                      activatingConnector != undefined
                        ? true
                        : false && !connectors.isAvailable
                    }
                    onClick={() =>
                      handleConnector(connectors.connectorFunction)
                    }>
										<div class="wallet-metamask-card">
											<div class="wallet-metamask-left-sec">
												<div class="wallet-metamask-img-sec">
													<img src={
                            window.location.origin +
                            "/assets/images/metamask.svg"
                          } alt="MetaMask" />
												</div>
												<div class="wallet-metamask-info-sec">
													<h4>MetaMask</h4>
												</div>
											</div>
											<div class="wallet-metamask-right-sec">
												<p>Popular</p>
											</div>
										</div>
									</button>
								</div> */}
							</div>
          </div>
        </div>
      </div>
      <VerificationCodeModal 
        show={show}
        handleClose={handleClose}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  login: state.users.loginInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ConnectWallet);
