import {
  FETCH_NFT_LIST_START,
  FETCH_NFT_LIST_SUCCESS,
  FETCH_NFT_LIST_FAILURE,
  FETCH_SINGLE_NFT_START,
  FETCH_SINGLE_NFT_SUCCESS,
  FETCH_SINGLE_NFT_FAILURE,
  SAVE_NFT_START,
  SAVE_NFT_SUCCESS,
  SAVE_NFT_FAILURE,
  DELETE_NFT_START,
  DELETE_NFT_SUCCESS,
  DELETE_NFT_FAILURE,
  FETCH_NFT_PROPERTIES_LIST_START,
  FETCH_NFT_PROPERTIES_LIST_SUCCESS,
  FETCH_NFT_PROPERTIES_LIST_FAILURE,
  SAVE_NFT_PROPERTIES_START,
  SAVE_NFT_PROPERTIES_SUCCESS,
  SAVE_NFT_PROPERTIES_FAILURE,
  DELETE_NFT_PROPERTIES_START,
  DELETE_NFT_PROPERTIES_SUCCESS,
  DELETE_NFT_PROPERTIES_FAILURE,
  DELETE_NFT_IMAGES_START,
  DELETE_NFT_IMAGES_SUCCESS,
  DELETE_NFT_IMAGES_FAILURE,
  DEPLOY_CONTRACT_SAVE_START,
  DEPLOY_CONTRACT_SAVE_SUCCESS,
  DEPLOY_CONTRACT_SAVE_FAILURE,
  NFT_GENERATE_PAYMENT_START,
  NFT_GENERATE_PAYMENT_SUCCESS,
  NFT_GENERATE_PAYMENT_FAILURE,
  UPLOAD_NFT_FILES_START,
  UPLOAD_NFT_FILES_SUCCESS,
  UPLOAD_NFT_FILES_FAILURE,
  EXPORT_SAMPLE_NFT_START,
  EXPORT_SAMPLE_NFT_SUCCESS,
  EXPORT_SAMPLE_NFT_FAILURE
} from "./ActionConstant";

// Get Nft details actions.

export function fetchNftListStart(data) {
  return {
    type: FETCH_NFT_LIST_START,
    data,
  };
}

export function fetchNftListSuccess(data) {
  return {
    type: FETCH_NFT_LIST_SUCCESS,
    data,
  };
}

export function fetchNftListFailure(error) {
  return {
    type: FETCH_NFT_LIST_FAILURE,
    error,
  };
}

export function saveNftStart(data) {
  return {
   type: SAVE_NFT_START,
   data,
  };
 }
 
 export function saveNftSuccess(data) {
  return {
   type: SAVE_NFT_SUCCESS,
   data,
  };
 }
 
 export function saveNftFailure(error) {
  return {
   type: SAVE_NFT_FAILURE,
   error,
  };
 }

 export function fetchSingleNftStart(data) {
  return {
   type: FETCH_SINGLE_NFT_START,
   data,
  };
 }
 
 export function fetchSingleNftSuccess(data) {
  return {
   type: FETCH_SINGLE_NFT_SUCCESS,
   data,
  };
 }
 
 export function fetchSingleNftFailure(error) {
  return {
   type: FETCH_SINGLE_NFT_FAILURE,
   error,
  };
 }
 
 export function deleteNftStart(data) {
  return {
   type: DELETE_NFT_START,
   data,
  };
 }
 
 export function deleteNftSuccess(data) {
  return {
   type: DELETE_NFT_SUCCESS,
   data,
  };
 }
 
 export function deleteNftFailure(error) {
  return {
   type: DELETE_NFT_FAILURE,
   error,
  };
 }

 export function fetchNftPropertiesListStart(data) {
  return {
    type: FETCH_NFT_PROPERTIES_LIST_START,
    data,
  };
}

export function fetchNftPropertiesListSuccess(data) {
  return {
    type: FETCH_NFT_PROPERTIES_LIST_SUCCESS,
    data,
  };
}

export function fetchNftPropertiesListFailure(error) {
  return {
    type: FETCH_NFT_PROPERTIES_LIST_FAILURE,
    error,
  };
}

export function saveNftPropertiesStart(data) {
  return {
   type: SAVE_NFT_PROPERTIES_START,
   data,
  };
 }
 
 export function saveNftPropertiesSuccess(data) {
  return {
   type: SAVE_NFT_PROPERTIES_SUCCESS,
   data,
  };
 }
 
 export function saveNftPropertiesFailure(error) {
  return {
   type: SAVE_NFT_PROPERTIES_FAILURE,
   error,
  };
 }

 export function deleteNftPropertiesStart(data) {
  return {
   type: DELETE_NFT_PROPERTIES_START,
   data,
  };
 }
 
 export function deleteNftPropertiesSuccess(data) {
  return {
   type: DELETE_NFT_PROPERTIES_SUCCESS,
   data,
  };
 }
 
 export function deleteNftPropertiesFailure(error) {
  return {
   type: DELETE_NFT_PROPERTIES_FAILURE,
   error,
  };
 }

 export function deleteNftImagesStart(data) {
  return {
   type: DELETE_NFT_IMAGES_START,
   data,
  };
 }
 
 export function deleteNftImagesSuccess(data) {
  return {
   type: DELETE_NFT_IMAGES_SUCCESS,
   data,
  };
 }
 
 export function deleteNftImagesFailure(error) {
  return {
   type: DELETE_NFT_IMAGES_FAILURE,
   error,
  };
 }

 export function deployContractSaveStart(data) {
  return {
   type: DEPLOY_CONTRACT_SAVE_START,
   data,
  };
 }
 
 export function deployContractSaveSuccess(data) {
  return {
   type: DEPLOY_CONTRACT_SAVE_SUCCESS,
   data,
  };
 }
 
 export function deployContractSaveFailure(error) {
  return {
   type: DEPLOY_CONTRACT_SAVE_FAILURE,
   error,
  };
 }

 export function nftGeneratePaymentSaveStart(data) {
  return {
   type: NFT_GENERATE_PAYMENT_START,
   data,
  };
 }
 
 export function nftGeneratePaymentSaveSuccess(data) {
  return {
   type: NFT_GENERATE_PAYMENT_SUCCESS,
   data,
  };
 }
 
 export function nftGeneratePaymentSaveFailure(error) {
  return {
   type: NFT_GENERATE_PAYMENT_FAILURE,
   error,
  };
 }

 export function uploadNFTFilesStart(data) {
  return {
    type: UPLOAD_NFT_FILES_START,
    data,
  };
}

export function uploadNFTFilesSuccess(data) {
  return {
    type: UPLOAD_NFT_FILES_SUCCESS,
    data,
  };
}

export function uploadNFTFilesFailure(error) {
  return {
    type: UPLOAD_NFT_FILES_FAILURE,
    error,
  };
}

export function exportSampleNftStart(data) {
  return {
    type: EXPORT_SAMPLE_NFT_START,
    data,
  };
}

export function exportSampleNftSuccess(data) {
  return {
    type: EXPORT_SAMPLE_NFT_SUCCESS,
    data,
  };
}

export function exportSampleNftFailure(error) {
  return {
    type: EXPORT_SAMPLE_NFT_FAILURE,
    error,
  };
}