import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import { connect } from "react-redux";
import { fetchNftListStart, deleteNftStart } from "../store/actions/NftAction";
import GeneratorSelectModal from "./GeneratorSelectModal";
import { Dropdown } from "react-bootstrap";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";
import { useDispatch } from "react-redux";
import { createNotification } from "react-redux-notify";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    top: "8px",
    color: "#fff",
    fontSize: "1em",
    padding: "0.75em",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow placement="top" classes={classes} {...props} />;
}

const useTableStyles = makeStyles({
  table: {
    backgroundColor: "#171717",
    color: "#828282",
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: "#171717",
      color: "#828282",
      borderBottom: "1.5px solid #828282",
      minWidth: "150px",
      maxWidth: "200px",
    },
    "& .MuiTableCell-stickyHeader:first-child": {
      minWidth: "50px",
      maxWidth: "50px",
    },
    "& .MuiTableCell-body": {
      color: "#828282",
      borderBottom: "1.5px solid #828282",
      maxWidth: "250px",
      // overflow: "hidden",
    },
  },
  MuiTablePagination: {
    backgroundColor: "red",
  },
});

const usepaginationStyle = makeStyles({
  MuiTablePagination: {
    backgroundColor: "#171717",
    color: "#828282",
    "& .MuiIconButton-root.Mui-disabled": {
      color: "#828282",
      cursor: "not-allowed",
      pointerEvents: "auto",
    },
  },
});

const NftIndex = (props) => {
  const tableclasses = useTableStyles();

  const paginationStyle = usepaginationStyle();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [selectGeneratorModal, setSelectGeneratorModal] = useState({
    modalFlag: false,
    nft_id: null,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    props.dispatch(
      fetchNftListStart({ skip: page * rowsPerPage, take: rowsPerPage })
    );
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.dispatch(
      fetchNftListStart({ skip: newPage * rowsPerPage, take: rowsPerPage })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    props.dispatch(
      fetchNftListStart({
        skip: 0 * event.target.value,
        take: event.target.value,
      })
    );
  };

  const deleteNft = (nft_unique_id) => {
    props.dispatch(deleteNftStart({ nft_unique_id: nft_unique_id }));
  };

  const closeGenratorSelectModal = () => {
    setSelectGeneratorModal({
      ...selectGeneratorModal,
      nft_id: null,
      modalFlag: false,
    });
  };

  const copyToClipboard = (walletAddress) => {
    navigator.clipboard.writeText(walletAddress);
    const notificationMessage = getSuccessNotificationMessage(
      "Wallet address copied"
    );
    dispatch(createNotification(notificationMessage));
  };

  return (
    <>
      <div className="content-wrapper  min-heigth-100vh">
        <section className="content-header">
          <h1>Nft</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">
                <i className="fa fa-dashboard"></i> Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="/contract/nft-list">Nft</a>
            </li>
            <li className="breadcrumb-item active">Nft List</li>
          </ol>
        </section>
        <div className="container-fluid p-4">
          <div className="table-filter-wrapper">
            <TablePagination
              rowsPerPageOptions={[10, 15, 20]}
              component="div"
              count={props.nftList.loading ? 0 : props.nftList.data.total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              className={paginationStyle.MuiTablePagination}
              showFirstButton="true"
              showLastButton="true"
            />
          </div>
          <TableContainer component={Paper}>
            <Table
              stickyHeader
              aria-label="sticky table"
              className={tableclasses.table}
            >
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    <span className="text-bold">Contract</span>
                  </TableCell>
                  <TableCell>
                    <span className="text-bold">Symbol</span>
                  </TableCell>
                  <TableCell>
                    <span className="text-bold">Total Images</span>
                  </TableCell>
                  <TableCell>
                    <span className="text-bold">Contract Address</span>
                  </TableCell>
                  {/* <TableCell>
                    <span className="text-bold">Contract Address</span>
                  </TableCell> */}
                  <TableCell>
                    <span className="text-bold">Is Deployed</span>
                  </TableCell>
                  <TableCell>
                    <span className="text-bold">Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.nftList.loading ? (
                  <div className="p-3 w-100">
                    <h6 className="white-color text-bold text-center">
                      Loading...
                    </h6>
                  </div>
                ) : (
                  <>
                    {props.nftList.data.nft.length > 0 &&
                      props.nftList.data.nft.map((data, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {page * rowsPerPage + 1 + index}
                          </TableCell>
                          <TableCell>
                            <Link
                              to={`/single-contract/${data.nft_unique_id}`}
                            >
                              <p className="text-no-overflow mb-0">
                                {" "}
                                {data.contract_name}
                              </p>
                            </Link>
                          </TableCell>
                          <TableCell>
                            <p className="mb-0"> {data.symbol}</p>
                          </TableCell>
                          <TableCell>
                            <p className="mb-0"> {data.total_images}</p>
                          </TableCell>
                          <TableCell>
                            <div className="account">
                              <p className="mb-0" onClick={() => copyToClipboard(data.contract_address)}>
                                {" "}
                                {data.contract_address
                                  ? 
                                  data.contract_address.substr(0, 5)+"..."+
                                  data.contract_address.substr(data.contract_address.length - 4)
                                  : "N/A"}
                              </p>
                            </div>
                            
                          </TableCell>
                          {/* <TableCell>
                            <p className="mb-0">
                              {" "}
                              {data.contract_address
                                ? data.contract_address
                                : "N/A"}
                            </p>
                          </TableCell> */}
                          <TableCell>
                            {data.is_deployed == 1 ? (
                              <span
                              className={`custom-badge single-line-text success`}
                            >
                              Yes
                            </span>
                            ) : (
                              <span
                              className={`custom-badge single-line-text danger`}
                            >
                              No
                            </span>
                            )}
                            
                          </TableCell>
                          <TableCell>
                            <div>
                              <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="action-btn">
                                  Action
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item href={`/single-contract/${data.nft_unique_id}`}>View</Dropdown.Item>
                                  {data.is_deployed == 0 ? (
                                    <Dropdown.Item href={`/contract/deploy-contract/${data.nft_unique_id}`}>
                                      Deploy contract
                                    </Dropdown.Item>
                                  ) : (
                                    <>
                                      <Dropdown.Item href={`/contract/nft-files-upload/${data.nft_unique_id}`}>
                                        Upload Images
                                      </Dropdown.Item>
                                      <Dropdown.Item href={`/contract/${data.nft_unique_id}/nft-images-list`}>
                                        Generated images
                                      </Dropdown.Item>
                                    </>
                                  )}
                                  <Dropdown.Item href={`/contract/edit-contract/${data.nft_unique_id}`}>Edit</Dropdown.Item>

                                  <Dropdown.Item onClick={() => deleteNft(data.nft_unique_id)}>Delete</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </>
                )}
              </TableBody>
            </Table>
            {!props.nftList.loading && props.nftList.data.nft.length < 0 && (
              <div className="">
                <h6 className="white-color text-bold text-center">
                  No data found
                </h6>
              </div>
            )}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 20]}
            component="div"
            count={props.nftList.loading ? 0 : props.nftList.data.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            className={paginationStyle.MuiTablePagination}
            showFirstButton={true}
            showLastButton={true}
          />
        </div>
      </div>
      <GeneratorSelectModal
        show={selectGeneratorModal.modalFlag}
        data={selectGeneratorModal}
        handleClose={closeGenratorSelectModal}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  nftList: state.nft.nftList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(NftIndex);
