import React from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const NFTImagesPayamentModal = (props) => {
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        centered
        className="custom-modal"
      >
        <Modal.Header>
          <div className="custom-header">
            <Modal.Title className="white-color mb-0">
              {props.headerTitle}
            </Modal.Title>
            <FontAwesomeIcon icon={faTimes} onClick={props.handleClose} />
          </div>
        </Modal.Header>
        <Modal.Body>
          {props.singleNft.loading ? (
            "Loading... ?"
          ) : (
            <>
              <section className="">
                <div className="">
                  <div className=" p-3 p-lg-4 custom-shadow m-0">
                    <div className="row no-gutters">
                      <div className="col-md-12">
                        <div className="tokens-info-wrapper pr-md-4">
                          <div className="info-wrap">
                            <h5 className="text-bold m-0 whitecolor">
                              Token Name <span className="mx-3">:</span>
                            </h5>
                            <h5 className="m-0">
                              {props.singleNft.data.nft.contract_name}
                            </h5>
                          </div>
                          <div className="info-wrap">
                            <h5 className="text-bold m-0 whitecolor">
                              Total Supply <span className="mx-3">:</span>
                            </h5>
                            <h5 className="m-0">
                              {props.singleNft.data.nft.total_supply}
                            </h5>
                          </div>
                          <div className="info-wrap">
                            <h5 className="text-bold m-0 whitecolor">
                              Token Symbol <span className="mx-3">:</span>
                            </h5>
                            <h5 className="m-0">
                              {props.singleNft.data.nft.symbol}
                            </h5>
                          </div>
                          <div className="info-wrap">
                            <h5 className="text-bold m-0 whitecolor">
                              Gas Fee <span className="mx-3">:</span>
                            </h5>
                            <h5 className="m-0"> 0.003</h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-3 align-items-center d-flex justify-content-center mt-3">
                        <div className="admin-amount-wrapper payment-modal w-100">
                          <div>
                            <div className="info-wrap">
                              <h5 className="text-bold m-0 whitecolor">
                                Gas Fee <span className="mx-3">:</span>
                              </h5>
                              <h5 className="m-0"> 0.005</h5>
                            </div>
                            <div className="info-wrap">
                              <h5 className="text-bold m-0 whitecolor">
                                Balance <span className="mx-3">:</span>
                              </h5>
                              <h5 className="m-0">1.000</h5>
                            </div>
                          </div>
                          <RadioGroup
                            aria-label="coin"
                            name="coin"
                            row
                            //value={}
                            // onChange={(event) =>
                            //   checkMetaMaskNetwork(event.target.value)
                            // }
                            className="justify-content-between"
                          >
                            <FormControlLabel
                              value="ether"
                              control={<Radio color="primary" />}
                              label="Ethereum"
                            />
                            <FormControlLabel
                              value="polygon"
                              control={<Radio color="primary" />}
                              label="Polygon"
                            />
                            <FormControlLabel
                              value="bnb"
                              control={<Radio color="primary" />}
                              label="BNB"
                            />
                          </RadioGroup>

                          <div className="mt-2 text-center">
                            <button
                              type="submit"
                              className="btn btn-primary withTheme"
                              onClick={props.generateFunction}
                              disabled={props.generateFunction.buttonDisable}
                            >
                              {props.genrateAction.loadingButttonContent ==
                              null
                                ? "Generate Images"
                                : props.genrateAction.loadingButttonContent}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NFTImagesPayamentModal;
