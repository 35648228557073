export const apiConstants = {
  LOGIN_BY: "manual",

  DEVICE_TYPE: "web",

  DEVICE_TOKEN: "123456",

  ERROR_CODE: [1000, 1001, 1002, 1003, 1004, 1005, 1006],

  settingsUrl: "https://api-xmint.blockstall.com/api/user/get_settings_json",

  ipfs_project_id: "2DFOxa6mhVAiQGiqgHqbytDtYRT",

  ipfs_project_secret: "f5fc3986a8bad1299751cd6b020c95ec"

};
