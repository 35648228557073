import { React, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import {
  saveNftStart,
} from "../store/actions/NftAction";

const CreateContract = (props) => {
  const [createNftInput, setCreateNftInput] = useState({
    contract_name: "",
    symbol: "",
    total_supply: "",
    wallet_address: "",
    royalty_wallet_address: "",
    royalty_percentage: "",
  });

  const createNftschema = Yup.object().shape({
    contract_name: Yup.string().required("Contract name is required"),
    symbol: Yup.string().required("Contract symbol is required"),
    wallet_address: Yup.string().required("Wallet Address is required"),
    royalty_wallet_address: Yup.string().required("Royalty Wallet Address is required"),
    // total_supply: Yup.number().required("Contract total is required"),
    royalty_percentage: Yup.number()
      .required("Contract total is required")
      .min(0,"Percentage should not be less than 0")
      .max(100,"Percentage should not be greater than 100"),
  });

  const handleSubmit = (value) => {
    props.dispatch(saveNftStart(value));
  };

  return (
    <>
      <section className="content-wrapper min-heigth-100vh">
        <div className="container-fluid">
          <Formik
            initialValues={{
              contract_name: "",
              symbol: "",
              // total_supply: "",
              wallet_address: "",
              royalty_wallet_address: "",
              royalty_percentage: "",
            }}
            className="w-100"
            validationSchema={createNftschema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ errors, touched, setFieldValue }) => (
              <Form noValidate>
                <div className="">
                  <div className="custom-box m-0 mt-3 p-3">
                    <div className="row">
                      {/* <div className="col-12">
                      <p className="mb-0">
                        Using this tool, you can: <br />
                        <ul className="afterLogin-ul">
                          <li>
                            Easily create any number of Coins / Tokens /
                            Cryptocurrency in just a few clicks.
                          </li>
                          <li>
                            Effortlessly create any type of Tokens: Utility
                            Tokens for your ICO, Security Tokens for your STO,
                            Asset Backed Tokens ( ABT ) etc.
                          </li>
                          <li>
                            Tokenize any of your real world assets ( Real
                            Estate, Art, Agriculture etc. ) in a few clicks.
                          </li>
                          <li>
                            Create Tokens that has all the regulatory compliance
                            of your Jurisdiction built in.
                          </li>
                        </ul>
                        <a
                          target="_blank"
                          className="white-color text-bold"
                          href="https://blog.gatecoin.com/why-asset-backed-tokens-will-be-mainstream-56f4aad704c4"
                        >
                          {" "}
                          Learn More.
                        </a>
                      </p>
                      <br />
                      <h3 className="text-bold m-0 text-center white-color mb-3">
                        IF YOU ARE READY, LETS START WITH THE BASIC DETAILS
                      </h3>
                    </div> */}
                      <div className="col-lg-12 p-0">
                        <div className="custom-box p-0 create-token-width">
                          <div className="createToken-header">
                            <h3 className="text-bold m-0 whitecolor">
                              Contract Details
                            </h3>
                          </div>
                          <div className="form-wrapper p-3">
                            <div className="row">
                              <div class="form-group col-lg-4">
                                <h5>
                                  Contract name{" "}
                                  <span class="text-danger">*</span>
                                </h5>
                                <div class="controls">
                                  <Field
                                    type="text"
                                    name="contract_name"
                                    placeholder="Contract Name"
                                    className={`no-padding form-control ${touched.contract_name &&
                                      errors.contract_name
                                      ? "is-invalid"
                                      : ""
                                      }`}
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name="contract_name"
                                    className="invalid-feedback mt-3"
                                  />
                                  <p className="mb-0 mt-2 text-muted">
                                    Choose a name for your token.
                                  </p>
                                </div>
                              </div>
                              <div class="form-group col-lg-4">
                                <h5>
                                  Contract Symbol{" "}
                                  <span class="text-danger">*</span>
                                </h5>
                                <div class="controls">
                                  <Field
                                    type="text"
                                    name="symbol"
                                    placeholder="Token Symbol"
                                    className={`no-padding form-control ${touched.symbol && errors.symbol
                                      ? "is-invalid"
                                      : ""
                                      }`}
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name="symbol"
                                    className="invalid-feedback mt-3"
                                  />
                                  <p className="mb-0 mt-2 text-muted">
                                    ( Ex: BTC, EOS, ETH, XLM etc. )
                                  </p>
                                </div>
                              </div>
                              <div class="form-group col-lg-4">
                                <h5>
                                  Owner Wallet Address{" "}
                                  <span class="text-danger">*</span>
                                </h5>
                                <div class="controls">
                                  <Field
                                    type="text"
                                    name="wallet_address"
                                    placeholder="Owner Wallet Address"
                                    className={`no-padding form-control ${touched.wallet_address && errors.wallet_address
                                      ? "is-invalid"
                                      : ""
                                      }`}
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name="wallet_address"
                                    className="invalid-feedback mt-3"
                                  />
                                </div>
                              </div>
                              <div class="form-group col-lg-4">
                                <h5>
                                  Royalty Wallet Address{" "}
                                  <span class="text-danger">*</span>
                                </h5>
                                <div class="controls">
                                  <Field
                                    type="text"
                                    name="royalty_wallet_address"
                                    placeholder="Royalty Wallet Address"
                                    className={`no-padding form-control ${touched.royalty_wallet_address && errors.royalty_wallet_address
                                      ? "is-invalid"
                                      : ""
                                      }`}
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name="royalty_wallet_address"
                                    className="invalid-feedback mt-3"
                                  />
                                </div>
                              </div>
                              <div class="form-group col-lg-4">
                                <h5>
                                  Royalty Percantage{" "}
                                  <span class="text-danger">*</span>
                                </h5>
                                <div class="controls">
                                  <Field
                                    type="number"
                                    name="royalty_percentage"
                                    placeholder="Royalty Percantage"
                                    className={`no-padding form-control ${touched.royalty_percentage && errors.royalty_percentage
                                      ? "is-invalid"
                                      : ""
                                      }`}
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name="royalty_percentage"
                                    className="invalid-feedback mt-3"
                                  />
                                </div>
                              </div>
                              {/* <div class="form-group col-lg-4">
                                <h5>
                                  Contract Total Supply{" "}
                                  <span class="text-danger">*</span>
                                </h5>
                                <div class="controls">
                                  <Field
                                    type="text"
                                    name="total_supply"
                                    placeholder="Token Decimals"
                                    className={`no-padding form-control ${
                                      touched.total_supply &&
                                      errors.total_supply
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name="total_supply"
                                    className="invalid-feedback mt-3"
                                  />
                                  <p className="mb-0 mt-2 text-muted">
                                    (Max number of Contracts to be created)
                                  </p>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 p-0 ">
                        <div className="row no-gutters">
                          <div className="d-flex justify-content-center my-3 w-100">
                            <button
                              type="submit"
                              className="btn btn-primary btn-medium withTheme ml-sm-3 mt-3 mt-md-0 text-bold"
                              disabled={props.saveNft.buttonDisable}
                            >
                              {props.saveNft.loadingButtonContent != null ?
                                props.saveNft.loadingButtonContent
                                : "Create contract"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </>
  );
};

const mapStateToPros = (state) => ({
  saveNft: state.nft.saveNft,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CreateContract);
