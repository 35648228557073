import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';


const CreateNFTStep4 = () => {
 return (
  <>
         <div className="nft-list-text">
             <Container>
             <ul>
                 <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus, esse! Repudiandae similique vel est, cum voluptate doloremque impedit soluta magni distinctio atque rem fugit ea enim a aut aliquid et.</li>
                 <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus, esse! Repudiandae similique vel est, cum voluptate doloremque impedit soluta magni distinctio atque rem fugit ea enim a aut aliquid et.</li>
                 <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus, esse! Repudiandae similique vel est, cum voluptate doloremque impedit soluta magni distinctio atque rem fugit ea enim a aut aliquid et.</li>

                 <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus, esse! Repudiandae similique vel est, cum voluptate doloremque impedit soluta magni distinctio atque rem fugit ea enim a aut aliquid et.</li>
                 </ul>
                  <div className="nft-video-sec-btn">
                 <Link to="/create-nft-step3" className="btn btn-md btn-medium btn-grad no-change p-3">Previous</Link>
                 <Link to="/create-nft-step4" className="btn btn-md btn-medium btn-grad no-change p-3">Next</Link>
             </div>
             </Container>
  </div>
  </>
 );
}

export default CreateNFTStep4;