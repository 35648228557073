import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { addNFTImageStart } from "../store/actions/NFTImagesActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes, faEdit } from "@fortawesome/free-solid-svg-icons";
import { fetchNftPropertiesListStart } from "../store/actions/NftAction";
import { useParams } from "react-router";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";

const AddNFTImage = (props) => {
  const { nft_unique_id } = useParams();

  const [nftImagesDatas, setNftImagesData] = useState({
    properties: [],
    name: "",
    file_name: "",
    description: "",
    external_url: "",
  });

  const [image, setImage] = useState({
    nft_image: {
      file: "",
      preview_image: "",
    },
  });

  const addNftImagesschema = Yup.object().shape({
    properties: Yup.array().of(
      Yup.object().shape({
        value: Yup.string().required("value is required"),
      })
    ),
    picture: Yup.mixed().required("Image is required"),
    name: Yup.string().required("Name is required"),
    file_name: Yup.string().required("File name is required"),
    description: Yup.string().required("Description is required"),
    external_url: Yup.string().required("External Url is required"),
  });

  useEffect(() => {
    props.dispatch(
      fetchNftPropertiesListStart({ nft_unique_id: nft_unique_id })
    );
  }, []);

  useEffect(() => {
    if (
      !props.propertiesList.loading &&
      props.propertiesList.data.nft_properties
    ) {
      setNftImagesData((prev) => ({
        ...prev.nftImagesDatas,
        properties: props.propertiesList.data.nft_properties.map(
          (properties, index) => {
            return {
              name: properties.name,
              value: "",
              nft_property_id: properties.nft_property_id,
            };
          }
        ),
      }));
    }
  }, [props.propertiesList.data]);

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   props.dispatch(addNFTImageStart(inputData));
  // };

  const handleAddImageChange = (event) => {
    let reader = new FileReader();
    let file = event.currentTarget.files[0];
    reader.onloadend = () => {
      setImage({
        ...image,
        nft_image: {
          file: file,
          preview_image: reader.result,
        },
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleAddImageRemove = () => {
    setImage({
      ...image,
      nft_image: {
        file: "",
        preview_image: "",
      },
    });
  };

  const handleSubmit = (values) => {
    const newData = {
      properties: JSON.stringify(values.properties),
      nft_unique_id: nft_unique_id,
      picture: image.nft_image.file,
      name: values.name,
      file_name: values.file_name,
      description: values.description,
      external_url: values.external_url,
    };
    props.dispatch(addNFTImageStart(newData));
  };

  const handleInputChange = (index, value) => {
    let newData = nftImagesDatas.properties.map(
      (data) => {
        if(index == data.nft_property_id){
          return { ...data , value : value};
        } 
        return data
      }
    )
    setNftImagesData({...nftImagesDatas , properties : newData})
  };

  const handleChange = e => {
    setNftImagesData({
      ...nftImagesDatas,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <div className="content-wrapper  min-heigth-100vh">
        <section className="content-header">
          <h1>Nft Add Image</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">
                <i className="fa fa-dashboard"></i> Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="/contract/nft-list">Nft</a>
            </li>
            <li className="breadcrumb-item active">Nft Add Image</li>
          </ol>
        </section>
        <div className="custom-box my-4">
          {props.propertiesList.loading ? (
            "Loading..."
          ) : (
            <Formik
              initialValues={{
                properties: nftImagesDatas.properties,
                picture: image.nft_image.file,
                name: nftImagesDatas.name,
                file_name: nftImagesDatas.file_name,
                description: nftImagesDatas.description,
                external_url: nftImagesDatas.external_url,
              }}
              className="w-100"
              validationSchema={addNftImagesschema}
              onSubmit={(values) => handleSubmit(values)}
              enableReinitialize
            >
              {({ errors, touched, setFieldValue, values }) => (
                <Form>
                  <Row>
                    <Col md={6}>
                      <Col md={12} >
                        <div className="form-group mb-3">
                          <h5 className="white-color text-bold">
                            Nft Name{" "}
                            <span className="text-danger">*</span>
                          </h5>
                          <div className="controls">
                            <Field
                              name="name"
                              type="text"
                              className={`form-control ${
                                touched.name && errors.name
                                  ? "is-invalid"
                                  : ""
                              }`}
                              onChange={(event) =>
                                handleChange(event)
                              }
                            />
                          </div>
                          <ErrorMessage
                            component="div"
                            name="name"
                            className="invalid-feedback mt-3"
                          />
                        </div>
                      </Col>
                      <Col md={12} >
                        <div className="form-group mb-3">
                          <h5 className="white-color text-bold">
                            File Name{" "}
                            <span className="text-danger">*</span>
                          </h5>
                          <div className="controls">
                            <Field
                              name="file_name"
                              type="text"
                              className={`form-control ${
                                touched.file_name && errors.file_name
                                  ? "is-invalid"
                                  : ""
                              }`}
                              onChange={(event) =>
                                handleChange(event)
                              }
                            />
                          </div>
                          <ErrorMessage
                            component="div"
                            name="file_name"
                            className="invalid-feedback mt-3"
                          />
                        </div>
                      </Col>
                      <Col md={12} >
                        <div className="form-group mb-3">
                          <h5 className="white-color text-bold">
                            Description{" "}
                            <span className="text-danger">*</span>
                          </h5>
                          <div className="controls">
                            <Field
                              name="description"
                              type="text"
                              className={`form-control ${
                                touched.description && errors.description
                                  ? "is-invalid"
                                  : ""
                              }`}
                              onChange={(event) =>
                                handleChange(event)
                              }
                            />
                          </div>
                          <ErrorMessage
                            component="div"
                            name="description"
                            className="invalid-feedback mt-3"
                          />
                        </div>
                      </Col>
                      <Col md={12} >
                        <div className="form-group mb-3">
                          <h5 className="white-color text-bold">
                            External Url{" "}
                            <span className="text-danger">*</span>
                          </h5>
                          <div className="controls">
                            <Field
                              name="external_url"
                              type="text"
                              className={`form-control ${
                                touched.external_url && errors.external_url
                                  ? "is-invalid"
                                  : ""
                              }`}
                              onChange={(event) =>
                                handleChange(event)
                              }
                            />
                          </div>
                          <ErrorMessage
                            component="div"
                            name="external_url"
                            className="invalid-feedback mt-3"
                          />
                        </div>
                      </Col>
                      <FieldArray name="properties">
                        {() =>
                          values.properties.map((properties, index) => {
                            const propertiesErrors =
                              (errors.properties?.length &&
                                errors.properties[index]) ||
                              {};
                            const propertiesTouched =
                              (touched.properties?.length &&
                                touched.properties[index]) ||
                              {};
                            return (
                              <Col md={12} key={index}>
                                <div className="form-group mb-3">
                                  <h5 className="white-color text-bold">
                                    {properties.name}{" "}
                                    <span className="text-danger">*</span>
                                  </h5>
                                  <div className="controls">
                                    <input
                                      name={`properties.${index}.value`}
                                      type="text"
                                      className={
                                        "form-control" +
                                        (propertiesErrors.value &&
                                        propertiesTouched.value
                                          ? " is-invalid"
                                          : "")
                                      }
                                      onChange={(event) =>
                                        handleInputChange(
                                          properties.nft_property_id,
                                          event.target.value
                                        )
                                      }
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name={`properties.${index}.value`}
                                      className="invalid-feedback mt-3"
                                    />
                                  </div>
                                </div>
                              </Col>
                            );
                          })
                        }
                      </FieldArray>
                      <Col md={12}>
                        <div className="block text-sm mb-2">
                          <h5 className="white-color text-bold">
                            NFT Image
                            <span className="text-danger">*</span>
                          </h5>
                          <div className="custom-file-btn-wrapper ">
                            <input
                              id="fileSelect"
                              type="file"
                              accept="image/*"
                              name="picture"
                              onChange={(event) => {
                                handleAddImageChange(event);
                              }}
                            />
                            <button className="btn btn-primary withTheme">
                              Choose NFT Image
                            </button>
                          </div>
                          <ErrorMessage
                            component="div"
                            name="picture"
                            className="invalid-feedback mt-3"
                          />
                        </div>
                      </Col>
                    </Col>
                    <Col md={6}>
                      <Row className="justify-content-md-center">
                        <Col md={8}>
                          {image.nft_image.preview_image != "" && (
                            <div className="edit-nftimage-preview-image-wrapper">
                              <img
                                src={image.nft_image.preview_image}
                                alt=""
                                className="settings-previewimage "
                              />
                              <div className="file-remove-btn">
                                <button
                                  className=" btn btn-info button-icon m-10 danger"
                                  aria-label="Delete"
                                  onClick={handleAddImageRemove}
                                >
                                  <svg
                                    fill="#fff"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    width="24"
                                  >
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div className="w-100 text-center">
                    <button
                      className="btn btn-primary withTheme"
                      type="submit"
                      disabled={props.addNFTImage.buttonDisable}
                    >
                      {props.addNFTImage.loadingButtonContent != null
                        ? props.addNFTImage.loadingButtonContent
                        : "Add NFT image"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  addNFTImage: state.nftImages.addNFTImage,
  propertiesList: state.nft.nftPropertiesList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AddNFTImage);
