import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';
import "./NFTLandingPage.css"

const CreateNFTStep2 = (props) => {
     const opts = {
      height: '390',
      width: '640',
      playerVars: {
        autoplay: 1,
      },
    };

    const [route, setRoute] = useState('/dashboard');

    useEffect(() => {
      if (props.match.params.nft_unique_id) {
        setRoute(`/contract/nft-properties/${props.match.params.nft_unique_id}`)
      }
    }, [])

 return (
    <>
      <div className="nft-video-sec text-center">
          <YouTube videoId="NNQLJcJEzv0" opts={opts} />
          <div className="nft-video-sec-btn">
            <Link to={route} className="btn btn-md btn-medium btn-grad no-change p-3">Skip & Continue</Link>
          </div>
      </div>
    </>
 );
}

export default CreateNFTStep2;