import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import configuration from "react-global-configuration";

const POLLING_INTERVAL = 12000

//export const MetaMask = new InjectedConnector({ supportedChainIds : [97]})

// export const walletconnect = new WalletConnectConnector({
//   rpc: { 1: process.env.RPC_URL_4 },
//   qrcode: true,
//   bridge: "https://bridge.walletconnect.org",
//   pollingInterval: POLLING_INTERVAL
// })

export const useMetaMaskConnector = () => {

  const netID = configuration.get("configData.network_id")
  ? Number(configuration.get("configData.network_id"))
  : 56;

  const MetaMask =  new InjectedConnector({ supportedChainIds : [netID]})

  return {MetaMask}

}

//velas mainnet

export const walletconnect = new WalletConnectConnector({
  rpc: {
      56: "https://bsc-dataseed1.ninicoin.io",
  },
  chainId : 56 ,
  qrcode: true,
});
// walletconnect.networkId = 56;