import React, { useState, useRef, useEffect } from "react";
import {
  Nav,
  Container,
  Navbar,
  Row,
  Col,
  Button,
  Form,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes, faEdit } from "@fortawesome/free-solid-svg-icons";
import {
  deleteNftPropertiesStart,
  saveNftPropertiesStart,
} from "../store/actions/NftAction";
import { connect } from "react-redux";

const NFTUploadFilesProperties = (props) => {
  const newlayerRef = useRef();

  const editPropRef = useRef();

  const handleAddLayers = (event, layerName) => {
    event.preventDefault();
    if (layerName.trim() === layerName) {
      const newLayer = {
        name: layerName,
        index: props.nftProperties.length,
        nft_images: [],
        nft_property_id: "",
        is_edited: 1,
      };
      props.setEditProperties(null);
      props.setNftProperties((prevState) => {
        return [...prevState, newLayer];
      });
      props.setLayerName("");
      if (props.nftProperties.length > 0) {
        props.setActiveLayer(newLayer);
      } else {
        props.setActiveLayer(newLayer);
      }
      newlayerRef.current.blur();
    }
  };

  const handleLayerChange = (index) => {
    props.setActiveLayer(props.nftProperties[index]);
    props.setEditProperties(null);
  };

  const handleDeleteProperties = () => {
    props.setEditProperties(null);
    if (props.activeLayer.nft_property_id != "") {
      props.dispatch(
        deleteNftPropertiesStart({
          nft_property_id: props.activeLayer.nft_property_id,
          nft_unique_id: props.match.params.nft_unique_id,
        })
      );
    } else {
      props.setNftProperties(
        props.nftProperties.filter(
          (data) => data.index != props.activeLayer.index
        )
      );
      props.setActiveLayer({});
    }
  };

  const handleEditProperties = () => {
    props.setEditProperties(props.activeLayer.index);
  };

  useEffect(() => {
    if (props.editProperties != null) {
      editPropRef.current.focus();
    } else {
    }
  }, [props.editProperties]);

  const handleLayerNameChange = (event) => {
    props.setNftProperties([
      ...props.nftProperties.map((data) => {
        if (data.index == props.activeLayer.index) {
          props.nftProperties[props.activeLayer.index].name =
            event.target.value;
          props.nftProperties[props.activeLayer.index].is_edited = 1;
        }
        return data;
      }),
    ]);
  };

  const handleSave = () => {
    props.dispatch(
      saveNftPropertiesStart({
        nft_unique_id: props.match.params.nft_unique_id,
        properties: JSON.stringify(props.nftProperties),
      })
    );
  };

  return (
    <>
      <Col xs={12} className="nft-layer-create upload mb-3">
        <h2 class="mb-3 white-color text-bold text-center">
          Add propertyname from excel sheet
        </h2>
        <h4 className="mb-0 white-color mb-3">
          Note : Need Atleast two properties.
        </h4>
        <form onSubmit={(event) => handleAddLayers(event, props.layerName)}>
          <div className="layer-input-box">
            <input
              type="text"
              className="m-0"
              placeholder="New Layer"
              value={props.layerName}
              onChange={(event) => props.setLayerName(event.target.value)}
              ref={newlayerRef}
            />
            <button
              type="submit"
              className="nft-layer-add-btn"
              disabled={props.layerName == "" ? true : false}
              onClick={(event) => handleAddLayers(event, props.layerName)}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        </form>
        {props.nftProperties.length > 0 ? (
          <div className="nft-upload-files-layers row no-gutters">
            {props.nftProperties.map((data, index) => (
              <>
                <div className={"col-4 "} key={index}>
                  <div className="layers-wrapper">
                    {props.editProperties == index &&
                      props.editProperties == index ? (
                      <div
                        className={`layers ${props.activeLayer.index == index ? "active" : ""
                          }`}
                      >
                        {props.editProperties == index ? (
                          <input
                            value={data.name}
                            ref={editPropRef}
                            onChange={(event) => handleLayerNameChange(event)}
                          />
                        ) : (
                          <h6 className="m-0">{data.name}</h6>
                        )}
                      </div>
                    ) : (
                      <div
                        className={`layers ${props.activeLayer.index == data.index ? "active" : ""
                          }`}
                        onClick={() => handleLayerChange(index)}
                      >
                        {props.editProperties == index ? (
                          <input value={data.name} ref={editPropRef} />
                        ) : (
                          <h6 className="m-0">{data.name}</h6>
                        )}
                      </div>
                    )}
                    {props.activeLayer.index == data.index && (
                      <div className="layer-actions">
                        <FontAwesomeIcon
                          icon={faEdit}
                          onClick={() => handleEditProperties()}
                        />
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="delete"
                          onClick={() => handleDeleteProperties()}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </>
            ))}
          </div>
        ) : null}
      </Col>
      <div className="buttons-wrapper">
        <button
          type="button"
          onClick={() => {
            props.handleComplete();
            handleSave();
          }}
          className="btn btn-primary withTheme"
          disabled={props.nftProperties.length < 2}
        >
          Continue
        </button>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  propertiesList: state.nft.nftPropertiesList,
  deleteNftProperties: state.nft.deleteNftProperties,
  saveNftProperties: state.nft.saveNftProperties,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(NFTUploadFilesProperties);
