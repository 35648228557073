import React from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const MintNowModal = (props) => {
  const mintnowSchema = Yup.object().shape({
    wallet_address: Yup.string().required("Wallet address is required"),
  });

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        centered
        className="custom-modal"
      >
        <Modal.Header>
          <div className="custom-header">
            <Modal.Title className="white-color mb-0">Mint now</Modal.Title>
            <FontAwesomeIcon icon={faTimes} onClick={props.handleClose} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <Formik
              initialValues={{
                wallet_address: props.minterWalletAddress,
              }}
              className="w-100"
              validationSchema={mintnowSchema}
              onSubmit={(event , values) => props.mintYourNFT(event)}
            >
              {({ errors, touched, setFieldValue }) => (
                <Form>
                  <div className="form-group mb-3">
                    <h5 className="white-color text-bold">
                      Wallet Address <span className="text-danger">*</span>
                    </h5>
                    <div className="controls">
                      <Field
                        type="text"
                        name="wallet_address"
                        placeholder="Enter Your Wallet Address"
                        className={`no-padding form-control ${
                          touched.wallet_address && errors.wallet_address ? "is-invalid" : ""
                        }`}
                        onChange={(event) =>
                          props.setMinterWalletAdddress(event.target.value)
                        }
                      />
                      <ErrorMessage
                        component="div"
                        name="wallet_address"
                        className="invalid-feedback mt-3"
                      />
                    </div>
                  </div>
                  <div className="buttons-wrapper">
                    <Button
                      variant="danger"
                      className="btn btn-primary withTheme"
                      onClick={props.handleClose}
                    >
                      Close
                    </Button>
                    <Button
                      disabled={props.mintButtonContent != "" ? true : false}
                      // onClick={(event) => props.mintYourNFT(event)}
                      type="submit"
                      className="btn btn-primary withTheme"
                      variant="primary"
                    >
                      {props.mintButtonContent != ""
                        ? props.mintButtonContent
                        : "Save"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MintNowModal;
