import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faTimes } from "@fortawesome/free-solid-svg-icons";

const GeneratorSelectModal = (props) => {
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        centered
        className="custom-modal"
        size="lg"
      >
        <Modal.Header>
          <div className="custom-header">
            <Modal.Title className="white-color mb-0">Select Generator</Modal.Title>
            <FontAwesomeIcon icon={faTimes} onClick={props.handleClose}/>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="genration-options-wrapper">
            <div>
              <h6 className="text-center white-color mb-3">Generator Creator panel with Manual Settings</h6>
              <Link
                className="btn btn-primary withTheme "
                to={`/contract/nft-properties/${props.data.nft_id}`}
              >
                NFT Generator Panel
              </Link>
            </div>
            <div>
              <h6 className="text-center white-color mb-3">Generator with excel and zip upload</h6>
              <Link
                className="btn btn-primary withTheme "
                to={`/contract/nft-files-upload/${props.data.nft_id}`}
              >
                NFT Excel Generator
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GeneratorSelectModal;
