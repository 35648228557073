import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  editUserDetails,
  updateUserDetailsStart,
  fetchUserDetailsStart,
} from "../store/actions/UserAction";

const EditProfile = (props) => {
  const [editProfileData, setEditProfileData] = useState({
    name: "",
    desc: "",
    email: "",
    picture: "",
    mobile: "",
  });

  useEffect(() => {
    if (props.profile.loading) props.dispatch(fetchUserDetailsStart());
  }, []);

  useEffect(() => {
    if (!props.profile.loading) {
      setEditProfileData({
        name: props.profile.data.name,
        desc: props.profile.data.about,
        email: props.profile.data.email,
        picture: "",
        // mobile: Number(props.profile.data.mobile),
      });
    }
  }, [props.profile.data]);

  const handleSubmit = () => {
    // if (Object.keys(profileInputData).length > 0)
    props.dispatch(updateUserDetailsStart(editProfileData));
    // else props.dispatch(updateUserDetailsStart());
  };

  const editProfileSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    name: Yup.string().required("Name is required"),
    // mobile: Yup.number().required("Mobile number is required"),
    desc: Yup.string().nullable(),
    picture: Yup.mixed(),
  });

  return (
    <>
      <div className="content-wrapper min-heigth-100vh">
        <section className="content-header">
          <h1>Edit Profile</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">
                <i className="fa fa-dashboard"></i> Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="#">Profile</a>
            </li>
            <li className="breadcrumb-item">
              <a href="#">Edit Profile</a>
            </li>
          </ol>
        </section>
        {props.profile.loading ? (
          "loading...."
        ) : (
          <section className="full-height-section">
            <div className="container place-center">
              <div class="custom-box">
                <h2 className="mb-3 whitecolor text-bold text-center">
                  Edit Profile
                </h2>
                <div class="row">
                  <div class="col">
                    <Formik
                      enableReinitialize
                      initialValues={editProfileData}
                      validationSchema={editProfileSchema}
                      onSubmit={(values) => handleSubmit(values)}
                    >
                      {({ errors, touched, setFieldValue }) => (
                        <Form noValidate>
                          <div className="row">
                            <div class="form-group col-md-6">
                              <h5>
                                Name <span class="text-danger">*</span>
                              </h5>
                              <div class="controls">
                                <Field
                                  type="text"
                                  name="name"
                                  placeholder="Name"
                                  className={`no-padding form-control ${
                                    touched.name && errors.name
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  onChange={(event) => {
                                    props.dispatch(
                                      editUserDetails(
                                        event.currentTarget.name,
                                        event.currentTarget.value
                                      )
                                    );
                                  }}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="name"
                                  className="invalid-feedback mt-3"
                                />
                              </div>
                            </div>
                            <div class="form-group col-md-6">
                              <h5>
                                Email <span class="text-danger">*</span>
                              </h5>
                              <div class="controls">
                                <Field
                                  type="email"
                                  name="email"
                                  placeholder="Email"
                                  className={`no-padding form-control ${
                                    touched.email && errors.email
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  onChange={(event) => {
                                    props.dispatch(
                                      editUserDetails(
                                        event.currentTarget.name,
                                        event.currentTarget.value
                                      )
                                    );
                                  }}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="email"
                                  className="invalid-feedback mt-3"
                                />
                              </div>
                            </div>
                            {/* <div class="form-group col-md-6">
                              <h5>
                                Mobile Number <span class="text-danger">*</span>
                              </h5>
                              <div class="controls">
                                <Field
                                  type="number"
                                  name="mobile"
                                  placeholder="Mobile"
                                  className={`no-padding form-control ${
                                    touched.mobile && errors.mobile
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  onChange={(event) => {
                                    props.dispatch(
                                      editUserDetails(
                                        event.currentTarget.name,
                                        event.currentTarget.value
                                      )
                                    );
                                  }}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="mobile"
                                  className="invalid-feedback mt-3"
                                />
                              </div>
                            </div> */}
                            <div class="form-group col-md-6">
                              <h5>Picture</h5>
                              <div class="controls">
                                <input
                                  id="picture"
                                  className={`no-padding form-control file-input-height ${
                                    touched.picture && errors.picture
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  name="picture"
                                  placeholder="Select Picture"
                                  type="file"
                                  accept="image/*"
                                  // onChange={(event) => handleChangeImage(event)}
                                  onChange={(event) => {
                                    setEditProfileData({
                                      ...editProfileData,
                                      picture: event.target.files[0],
                                    });
                                  }}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="picture"
                                  className="invalid-feedback mt-3"
                                />
                              </div>
                            </div>
                            <div class="form-group col-md-12">
                              <h5>Description</h5>
                              <div class="controls">
                                <textarea
                                  id="picture"
                                  className={`no-padding form-control ${
                                    touched.desc && errors.picture
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  name="desc"
                                  placeholder="descrpition"
                                />
                                <ErrorMessage
                                  component="div"
                                  name="desc"
                                  className="invalid-feedback mt-3"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-center">
                            <Link
                              type="button"
                              className="btn btn-primary withTheme mt-3 mt-md-0"
                              to="/profile"
                            >
                              Cancel
                            </Link>
                            <button
                              type="submit"
                              className="btn btn-primary withTheme ml-sm-3 mt-3 mt-md-0"
                            >
                              Save
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  profileInputData: state.users.profileInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(EditProfile);
