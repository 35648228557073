import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import {
  fetchSingleNftStart,
  deleteNftStart,
} from "../store/actions/NftAction";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import Web3 from "web3";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../Helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import configuration from "react-global-configuration";
import { authContext } from "../auth/AuthProvider";

const $ = window.$;

// Declare IPFS json
const ipfsJson = require("nano-ipfs-store").at("https://ipfs.infura.io:5001");

const SingleContract = (props) => {
  const { nft_unique_id } = useParams();

  useEffect(() => {
    props.dispatch(
      fetchSingleNftStart({ nft_unique_id: nft_unique_id })
    );
  }, []);

  const deleteNft = (nft_unique_id) => {
    props.dispatch(deleteNftStart({ nft_unique_id: nft_unique_id }));
  };

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [mintButtonContent, setMintButtonContent] = useState("");

  const { auth } = useContext(authContext);

  const showLogin = async () => {
    $("#connect-wallet-popup").modal("show")
  };

  const copyToClipboard = (address, type) => {
    navigator.clipboard.writeText(address);
    let message = type + " copied";
    const notificationMessage = getSuccessNotificationMessage(
      message
    );
    props.dispatch(createNotification(notificationMessage));
  };

  return (
    <>
      <div className="content-wrapper  min-heigth-100vh">
        <section className="content-header">
          <h1>Nft</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">
                <i className="fa fa-dashboard"></i> Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="/contract/nft-list">Nft</a>
            </li>
            <li className="breadcrumb-item active">Nft Images List</li>
          </ol>
        </section>
        <div className="custom-box my-4">
          <div className="single-nft-image-view mt-3">
            {props.singleNFT.loading
              ? "Loading"
              : props.singleNFT.data.nft && (
                <>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="single-nft-card">
                        <h4>Name:</h4>
                        <p>{props.singleNFT.data.nft.contract_name}</p>
                      </div>
                      <div className="single-nft-card">
                        <h4>Symbol:</h4>
                        <p>{props.singleNFT.data.nft.symbol}</p>
                      </div>
                      <div className="single-nft-card">
                        <h4>Total Images:</h4>
                        <p>
                          {
                            props.singleNFT.data.nft.total_images
                          }
                        </p>
                      </div>
                      <div className="single-nft-card">
                        <h4>Wallet Address:</h4>
                        <p>
                          <div
                            className="properties-wrapper cursor-pointer"
                            onClick={() => copyToClipboard(props.singleNFT.data.nft.wallet_address, "Wallet Address")}
                          >
                            <div className="single-nft-card">
                              <p>
                                {props.singleNFT.data.nft.wallet_address
                                  ?
                                  props.singleNFT.data.nft.wallet_address.substr(0, 5) + "..." +
                                  props.singleNFT.data.nft.wallet_address.substr(props.singleNFT.data.nft.wallet_address.length - 4)
                                  : "N/A"}
                              </p>
                            </div>
                          </div>
                        </p>
                      </div>
                      <div className="single-nft-card">
                        <h4>Royalty Wallet Address:</h4>
                        <p>
                          <div
                            className="properties-wrapper cursor-pointer"
                            onClick={() => copyToClipboard(props.singleNFT.data.nft.royalty_wallet_address, "Royalty Wallet Address")}
                          >
                            <div className="single-nft-card">
                              <p>
                                {props.singleNFT.data.nft.royalty_wallet_address
                                  ?
                                  props.singleNFT.data.nft.royalty_wallet_address.substr(0, 5) + "..." +
                                  props.singleNFT.data.nft.royalty_wallet_address.substr(props.singleNFT.data.nft.royalty_wallet_address.length - 4)
                                  : "N/A"}
                              </p>
                            </div>
                          </div>
                        </p>
                      </div>
                      <div className="single-nft-card">
                        <h4>Royalty Percentage:</h4>
                        <p>
                          {
                            props.singleNFT.data.nft.royalty_percentage
                          } %
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="single-nft-card">
                        <h4>Contract Address:</h4>
                        <p>
                          <div
                            className="properties-wrapper cursor-pointer"
                            onClick={() => copyToClipboard(props.singleNFT.data.nft.contract_address, "Contract Address")}
                          >
                            <div className="single-nft-card">
                              <p>
                                {props.singleNFT.data.nft.contract_address
                                  ?
                                  props.singleNFT.data.nft.contract_address.substr(0, 5) + "..." +
                                  props.singleNFT.data.nft.contract_address.substr(props.singleNFT.data.nft.contract_address.length - 4)
                                  : "N/A"}
                              </p>
                            </div>
                          </div>
                        </p>
                      </div>
                      <div className="single-nft-card">
                        <h4>Is Deployed:</h4>
                        <div>
                          {props.singleNFT.data.nft.is_deployed == 1 ? (
                            <span
                              className={`custom-badge single-line-text success`}
                            >
                              Yes
                            </span>
                          ) : (
                            <span
                              className={`custom-badge single-line-text danger`}
                            >
                              No
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="single-nft-card">
                        <h4>Action:</h4>
                      </div>
                      <div>
                        <div className="buttons-wrapper d-block">
                          {props.singleNFT.data.nft.is_deployed == 0 ? (
                            <Link
                              to={`/contract/deploy-contract/${props.singleNFT.data.nft.nft_unique_id}`}
                              className="btn btn-primary withTheme mr-2 mt-2"
                            >
                              Deploy contract
                            </Link>
                          ) : (
                            <>
                              <Link
                                to={`/contract/nft-files-upload/${props.singleNFT.data.nft.nft_unique_id}`}
                                className="btn btn-primary withTheme mr-2 mt-2"
                              >
                                Upload Images
                              </Link>
                              <Link
                                to={`/contract/${props.singleNFT.data.nft.nft_unique_id}/add-nft-image`}
                                className="btn btn-primary withTheme mr-2 mt-2">
                                Add NFT Image
                              </Link>
                              <Link
                                to={`/contract/${props.singleNFT.data.nft.nft_unique_id}/nft-images-list`}
                                className="btn btn-primary withTheme mr-2 mt-2"
                              >
                                Generated images
                              </Link>
                            </>
                          )}
                          <button
                            onClick={() => deleteNft(props.singleNFT.data.nft.nft_unique_id)}
                            className="btn btn-primary withTheme mr-2 mt-2"
                          >
                            <span>
                              Delete
                            </span>
                          </button>
                          <Link
                            to={`/contract/edit-contract/${props.singleNFT.data.nft.nft_unique_id}`}
                            className="btn btn-primary withTheme mr-2 mt-2"
                          >
                            Edit
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  singleNFT: state.nft.singleNft,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SingleContract);
