import { React, useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import {
  fetchSingleNftStart,
  saveNftStart,
} from "../store/actions/NftAction";
import { Redirect, useParams } from "react-router";

const EditContract = (props) => {

  const { nft_unique_id } = useParams();

  useEffect(() => {
    props.dispatch(fetchSingleNftStart({ nft_unique_id: nft_unique_id }));
  }, []);

  const createNftschema = Yup.object().shape({
    contract_name: Yup.string().required("Contract name is required"),
    symbol: Yup.string().required("Contract symbol is required"),
    // total_supply: Yup.number().required("Contract total is required"),
    wallet_address: Yup.string().required("Wallet Address is required"),
    royalty_wallet_address: Yup.string().required("Royalty Wallet Address is required"),
    royalty_percentage: Yup.number().required("Contract total is required"),
  });

  const handleSubmit = (value) => {
    props.dispatch(saveNftStart(value));
  };

  return (
    <>
      <section className="content-wrapper min-heigth-100vh">
        <div className="container-fluid">
          {props.singleNft.loading ? "" : (
            <Formik
              initialValues={{
                contract_name: props.singleNft.data.nft.contract_name,
                symbol: props.singleNft.data.nft.symbol,
                wallet_address: props.singleNft.data.nft.wallet_address,
                royalty_wallet_address: props.singleNft.data.nft.royalty_wallet_address,
                royalty_percentage: props.singleNft.data.nft.royalty_percentage,
                nft_unique_id: nft_unique_id,
              }}
              className="w-100"
              validationSchema={createNftschema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ errors, touched, setFieldValue }) => (
                <Form noValidate>
                  <div className="">
                    <div className="custom-box m-0 mt-3 p-3">
                      <div className="row">
                        <div className="col-lg-12 p-0">
                          <div className="custom-box p-0 create-token-width">
                            <div className="createToken-header">
                              <h3 className="text-bold m-0 whitecolor">
                                Contract Details
                              </h3>
                            </div>
                            <div className="form-wrapper p-3">
                              <div className="row">
                                <div class="form-group col-lg-4">
                                  <h5>
                                    Contract name{" "}
                                    <span class="text-danger">*</span>
                                  </h5>
                                  <div class="controls">
                                    <Field
                                      type="text"
                                      name="contract_name"
                                      placeholder="Contract Name"
                                      className={`no-padding form-control ${
                                        touched.contract_name &&
                                        errors.contract_name
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="contract_name"
                                      className="invalid-feedback mt-3"
                                    />
                                    <p className="mb-0 mt-2 text-muted">
                                      Choose a name for your token.
                                    </p>
                                  </div>
                                </div>
                                <div class="form-group col-lg-4">
                                  <h5>
                                    Contract Symbol{" "}
                                    <span class="text-danger">*</span>
                                  </h5>
                                  <div class="controls">
                                    <Field
                                      type="text"
                                      name="symbol"
                                      placeholder="Token Symbol"
                                      className={`no-padding form-control ${
                                        touched.symbol && errors.symbol
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="symbol"
                                      className="invalid-feedback mt-3"
                                    />
                                    <p className="mb-0 mt-2 text-muted">
                                      ( Ex: BTC, EOS, ETH, XLM etc. )
                                    </p>
                                  </div>
                                </div>
                                <div class="form-group col-lg-4">
                                  <h5>
                                    Owner Wallet Address{" "}
                                    <span class="text-danger">*</span>
                                  </h5>
                                  <div class="controls">
                                    <Field
                                      type="text"
                                      name="wallet_address"
                                      placeholder="Owner Wallet Address"
                                      className={`no-padding form-control ${
                                        touched.wallet_address &&
                                        errors.wallet_address
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="wallet_address"
                                      className="invalid-feedback mt-3"
                                    />
                                  </div>
                                </div>
                                <div class="form-group col-lg-4">
                                  <h5>
                                    Royalty Wallet Address{" "}
                                    <span class="text-danger">*</span>
                                  </h5>
                                  <div class="controls">
                                    <Field
                                      type="text"
                                      name="royalty_wallet_address"
                                      placeholder="Royalty Wallet Address"
                                      className={`no-padding form-control ${
                                        touched.royalty_wallet_address &&
                                        errors.royalty_wallet_address
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="royalty_wallet_address"
                                      className="invalid-feedback mt-3"
                                    />
                                  </div>
                                </div>
                                <div class="form-group col-lg-4">
                                  <h5>
                                    Royalty Percantage{" "}
                                    <span class="text-danger">*</span>
                                  </h5>
                                  <div class="controls">
                                    <Field
                                      type="number"
                                      name="royalty_percentage"
                                      placeholder="Royalty Percantage"
                                      className={`no-padding form-control ${
                                        touched.royalty_percentage && errors.royalty_percentage
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="royalty_percentage"
                                      className="invalid-feedback mt-3"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 p-0 ">
                          <div className="row no-gutters">
                            <div className="d-flex justify-content-center my-3 w-100">
                              <button
                                type="submit"
                                className="btn btn-primary btn-medium withTheme ml-sm-3 mt-3 mt-md-0 text-bold"
                                disabled={props.saveNft.buttonDisable}
                              >
                                {props.saveNft.loadingButtonContent != null ? 
                                props.saveNft.loadingButtonContent
                                : "Edit contract" }
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
          
        </div>
      </section>
    </>
  );
};

const mapStateToPros = (state) => ({
  saveNft: state.nft.saveNft,
  singleNft: state.nft.singleNft,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(EditContract);
