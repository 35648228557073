import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import {
  fetchListofNFTStart,
  deleteSingleNFTStart,
  paginateListOfNFTStart,
  searchNFTImageStart,
  sortListOfNFTStart,
  updateNFTMintStatusStart,
} from "../store/actions/NFTImagesActions";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import ReactPaginate from "react-paginate";
import Web3 from "web3";
import { apiConstants } from "../Constant/constants";
import MintNowModal from "./MintNowModal";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../Helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import configuration from "react-global-configuration";
import {
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
} from "@material-ui/core";
import CustomSelect from "../Helper/CustomSelect";
import { Alert, Dropdown } from "react-bootstrap";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import NftToken from "../../abis/NftToken.json";
import { authContext } from "../auth/AuthProvider";
import { fetchSingleNftStart } from "../store/actions/NftAction";

const $ = window.$;

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    top: "8px",
    color: "#fff",
    fontSize: "1em",
    padding: "0.75em",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow placement="top" classes={classes} {...props} />;
}

const usecheckboxStyle = makeStyles((theme) => ({
  root: {
    color: "#ab8ce4 !important",
  }
}));

function CustomCheckbox(props) {
  const classes = usecheckboxStyle();
  return <Checkbox classes={classes} {...props} />;
}

//Declare IPFS
const auth =
  "Basic " +
  Buffer.from(
    apiConstants.ipfs_project_id + ":" + apiConstants.ipfs_project_secret
  ).toString("base64");
const ipfsClient = require("ipfs-http-client");
const ipfs = ipfsClient({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  // apiPath: '/api/v0',
  headers: {
    authorization: auth,
  },
}); // leaving out the arguments will default to these values

const ListOfNFT = (props) => {
  const { nft_unique_id } = useParams();

  useEffect(() => {
    props.dispatch(
      fetchSingleNftStart({ nft_unique_id: nft_unique_id })
    );
    props.dispatch(
      fetchListofNFTStart({ skip: 0, take: 10, nft_unique_id: nft_unique_id })
    );
  }, []);

  const [currentPage, setCurrentPage] = useState(0);

  const PER_PAGE = 10;
  const offset = currentPage * PER_PAGE;
  const [pageCount, setPageCount] = useState(0);

  const [skipInitialRender, setSkipInitialRender] = useState(false);

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [minterWalletAddress, setMinterWalletAdddress] = useState("");
  const [mintButtonContent, setMintButtonContent] = useState("");
  const [currentData, setCurrentData] = useState([]);
  const [mintNowModal, setMintNowModal] = useState(false);

  const [mintNowinputData, setMintNowInputData] = useState({
    wallet_address: "",
  });

  const [searchInputData, setSearchInputData] = useState({
    searchKey: "",
    searchStatus: false,
  });

  const [sortby, setSortby] = useState(null);

  const [selecteditems, setSelectedItems] = useState([]);

  const { auth } = useContext(authContext);

  const netID = configuration.get("configData.network_id")
    ? configuration.get("configData.network_id")
    : "97";

  // Generate metadata json file.
  const generateJson = async (data, allWords) => {
    let attributes = [];

    data.properties.map((property) =>
      attributes.push({ trait_type: property.name, value: (property.values ? property.values.value : "") })
    );
    console.log("attribute_values", attributes);
    const metadata = JSON.stringify({
      description: data.description,
      external_url: data.external_url,
      image: data.picture,
      name: data.name,
      text: allWords,
      attributes,
    });
    console.log("Json", metadata);
    return metadata;
  };

  const mintYourNFT = async (data) => {
    setMintButtonContent("Initiated...");

    try {
      let currentData = data;
      setMintButtonContent((prevState) => "Connecting to Blockchain");

      let allWords = "";
      currentData.properties.map((property) => {
        allWords = allWords + " " + (property.values ? property.values.value : "");
      });

      console.log("all words", allWords);
      const json = generateJson(currentData, allWords)
        .then(async (val) => {
          try {
            console.log("Val", val);
            val = Buffer(val);
            const cidArr = await ipfs.add(val);
            const cid = cidArr[0].hash;
            console.log("cid", cid);
            console.log("minter address", data.nft.wallet_address);

            const web3 = window.web3;
            let nftContractData;
            const NFTData = NftToken.networks[netID];
            // if (NFTData) {
            //   nftContractData = await new web3.eth.Contract(
            //     NftToken.abi,
            //     data.nft.wallet_address
            //   );
            // } else {
            //   window.alert("NFT contract not deployed to detected network.");
            // }
            nftContractData = await new web3.eth.Contract(
              NftToken.abi,
              data.nft.contract_address // contract address            
            );

            nftContractData.methods
              .mint(
                data.nft.wallet_address,
                "https://ipfs.infura.io/ipfs/" + cid,
                allWords
              )
              .send({ from: auth.accounts })
              .on("error", (error) => {
                let notificationMessage;
                if (error.message == undefined) {
                  notificationMessage = getErrorNotificationMessage(
                    "Same Wallet can't have more than 2 NFT! Use different wallet address"
                  );
                } else {
                  notificationMessage = getErrorNotificationMessage(
                    error.message
                  );
                }
                props.dispatch(createNotification(notificationMessage));
                setMintButtonContent("");
              })
              .once("receipt", (receipt) => {
                setLoading(false);
                setMintButtonContent("");
                props.dispatch(
                  updateNFTMintStatusStart({
                    merge_image_unique_id: data.merge_image_unique_id,
                    mint_status: 3,
                  })
                );
              });
          } catch (error) {
            console.log("Error", error);
            const notificationMessage = getErrorNotificationMessage(
              "Invalid wallet address"
            );
            props.dispatch(createNotification(notificationMessage));
            setMintButtonContent("");
          }

        });

    } catch (error) {
      console.log("error catch", error);
      setMintButtonContent("");
      const notificationMessage = getErrorNotificationMessage(
        "Something went wrong. Please refresh the page and try again."
      );
      props.dispatch(createNotification(notificationMessage));
    }

  };


  const handlePageClick = (data) => {
    let selected = data.selected;
    setCurrentPage(selected);
    setSelectedItems([]);
    setCurrentData([]);
    if (!searchInputData.searchStatus) {
      props.dispatch(
        paginateListOfNFTStart({
          skip: selected * PER_PAGE,
          take: PER_PAGE,
          nft_unique_id: nft_unique_id
        })
      );
    }
  };

  useEffect(() => {
    if (!props.listOFNFT.loading) {
      if (sortby) {
        setPageCount(Math.ceil(props.listOFNFT.data.length / PER_PAGE));
      } else {
        setPageCount(Math.ceil(props.listOFNFT.total / PER_PAGE));
      }
    }
  }, [props.listOFNFT.data]);

  const handleNFTDelete = (id) => {
    props.dispatch(deleteSingleNFTStart({ merge_image_ids: id, nft_unique_id: nft_unique_id }));
  };

  const handleNFTDeleteBulk = (id) => {
    props.dispatch(deleteSingleNFTStart({ merge_image_ids: id, nft_unique_id: nft_unique_id }));
    setSelectedItems([]);
    setCurrentData([]);
  };

  // useEffect(() => {
  //   if (skipInitialRender && deleteNFT != null) {
  //     props.dispatch(deleteSingleNFTStart({ merge_image_ids:  deleteNFT ,  nft_unique_id: nft_unique_id }));
  //   }
  //   setSkipInitialRender(true);
  // }, [deleteNFT]);

  const handleMintNowModalClose = () => {
    setMintNowModal(false);
    setMintNowInputData({
      ...mintNowinputData,
      wallet_address: "",
    });
    setMinterWalletAdddress("");
  };

  useEffect(() => {
    if (skipInitialRender && searchInputData.searchKey != "") {
      const timer = setTimeout(() => {
        props.dispatch(
          searchNFTImageStart({ search_key: searchInputData.searchKey, nft_unique_id: nft_unique_id })
        );
      }, 1000);
      return () => clearTimeout(timer);
    } else if (skipInitialRender) {
      const timer = setTimeout(() => {
        props.dispatch(
          searchNFTImageStart({ search_key: searchInputData.searchKey, nft_unique_id: nft_unique_id })
        );
      }, 1000);
    }
    setSkipInitialRender(true)
  }, [searchInputData.searchKey]);

  const handleSearchInputClear = () => {
    setSearchInputData({
      ...searchInputData,
      searchKey: "",
      searchStatus: false,
    });
    props.dispatch(
      paginateListOfNFTStart({
        skip: 0,
        take: PER_PAGE,
        nft_unique_id: nft_unique_id
      })
    );
    setCurrentPage(0);
  };

  const handleNFTSort = (sort_by) => {
    setSortby(sort_by);
    setCurrentPage(0);
  };

  useEffect(() => {
    if (skipInitialRender) {
      if (sortby !== null) {
        props.dispatch(sortListOfNFTStart({ mint_status: sortby, nft_unique_id: nft_unique_id }));
      } else {
        props.dispatch(
          fetchListofNFTStart({ skip: 0, take: 10, nft_unique_id: nft_unique_id })
        );
      }
    }
  }, [sortby]);

  const handleSingleItemSelect = (id) => {
    if (selecteditems.length > 0) {
      const isExists = selecteditems.find((data) => data == id);
      if (isExists == id) {
        const newArray = selecteditems.filter((data) => data != id);
        setSelectedItems(newArray);
        setCurrentData(newArray);
      } else {
        setSelectedItems([...selecteditems, id]);
        setCurrentData([...currentData, id]);
      }
    } else {
      setSelectedItems([...selecteditems, id]);
      setCurrentData([...currentData, id]);
    }
  };

  const handleBulkItemSelect = () => {
    if (selecteditems.length > 0) {
      setSelectedItems([]);
      setCurrentData([]);
    } else {
      const bulkSelect = props.listOFNFT.data
        .slice(offset, offset + PER_PAGE)
        .map((data, index) => {
          return data;
        });

      setSelectedItems(bulkSelect);
      setCurrentData(bulkSelect);
    }
  };

  const showLogin = async () => {
    $("#connect-wallet-popup").modal("show")
  };

  return (
    <>
      <div className="content-wrapper  min-heigth-100vh">
        <section className="content-header">
          <h1>{!props.singleNFT.loading && props.singleNFT.data.nft && props.singleNFT.data.nft.contract_name}</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">
                <i className="fa fa-dashboard"></i> Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="/contract/nft-list">Nft</a>
            </li>
            <li className="breadcrumb-item active">Nft Images List</li>
          </ol>
        </section>
        <div className="custom-box">
          {props.listOFNFT.loading ? (
            "loading"
          ) : (
            <>
              <div className="list_nft_table_header">
                <div className="search-box">
                  <div className="search-box-icon">
                    <svg
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <input
                    className="search-box-icon-input"
                    type="text"
                    placeholder="Search for NFT Images"
                    aria-label="Search"
                    value={searchInputData.searchKey}
                    onChange={(event) =>
                      setSearchInputData({
                        ...searchInputData,
                        searchKey: event.target.value,
                        searchStatus: true,
                      })
                    }
                  />
                </div>
                <div className="clear-search">
                  {searchInputData.searchKey != "" && (
                    <button
                      className=" btn btn-info button-icon m-10 danger ml-3"
                      onClick={() => handleSearchInputClear()}
                    >
                      <svg
                        height="26"
                        viewBox="0 0 24 24"
                        width="26"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                      </svg>
                    </button>
                  )}
                </div>
                {/* <div className="text-right p-3  flex-1 flex-grow">
                  <button
                    onClick={connectingMetaMask}
                    className=" btn btn-primary withTheme"
                  >
                    {connectMetaMask ? account : "Connect Wallet"}
                  </button>
                </div> */}
              </div>
              <div className="list_nft_table_header2 px-3 pb-3">
                <div className="sortby-wrapper">
                  <CustomSelect
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    value={sortby}
                    label="Sort By"
                    onChange={(event) => handleNFTSort(event.target.value)}
                    selected={sortby}
                  >
                    <MenuItem value={null}>All</MenuItem>
                    <MenuItem value={1}>Created</MenuItem>
                    <MenuItem value={3}>Completed</MenuItem>
                  </CustomSelect>
                  {selecteditems.length > 0 && (
                    <>
                      {/* <button
                        onClick={(event) => {
                          mintYourNFT(data);
                        }}
                        className=" btn btn-primary withTheme"
                      >
                        Mint Now
                      </button> */}
                      <button
                        className=" btn btn-primary danger rounded h-fit-content"
                        aria-label="Delete"
                        disabled={
                          props.deleteSingleNFT.buttonDisabled
                        }
                        onClick={() =>
                          handleNFTDeleteBulk(
                            selecteditems.map((selected, index) => {
                              return selected.merge_image_id;
                            })
                          )
                        }
                      >
                        <svg
                          fill="#fff"
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                        >
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                        </svg>
                      </button>
                    </>
                  )}
                </div>

                <div className="add-nft-button">
                  <Link
                    to={`/contract/${nft_unique_id}/add-nft-image`}
                    className="btn btn-primary withTheme mr-2"
                  >
                    Add NFT Image
                  </Link>
                  <Link
                    to={`/contract/nft-files-upload/${nft_unique_id}`}
                    className="btn btn-primary withTheme"
                  >
                    Upload Images
                  </Link>
                </div>
              </div>
              {selecteditems.length > 0 && (
                <>
                  <div className="p-3">
                    <Alert
                      variant={"dark"}
                      className="text-center light-grey-alert"
                    >
                      All <strong>{selecteditems.length}</strong> images on this
                      page are selected.
                    </Alert>
                  </div>
                </>
              )}
              <div className="custom-table-wrapper">
                <table className="custom-table">
                  <thead>
                    <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                      <th>
                        {props.listOFNFT.data.length > 0 && (
                          <div className="select-checkbox">
                            <CustomCheckbox
                              onChange={handleBulkItemSelect}
                              checked={selecteditems.length > 0}
                            />
                          </div>
                        )}
                      </th>
                      <th className="px-4 py-3">S.No</th>
                      <th className="px-4 py-3">NFT Image</th>
                      <th className="px-4 py-3">NFT Name</th>
                      <th className="px-4 py-3">Status</th>
                      <th className="px-4 py-3">Active Status</th>
                      <th className="px-4 py-3">Action</th>
                    </tr>
                  </thead>
                  <tbody className="custom-table-body">
                    {props.listOFNFT.data.length > 0 &&
                      props.listOFNFT.data
                        .slice(offset, offset + PER_PAGE)
                        .map((data, index) => (
                          <tr
                            className={`${data.status == 3 ? "list-completed-image-bg" : ""
                              } ${selecteditems.filter(
                                (selected) =>
                                  selected.merge_image_id ==
                                  data.merge_image_id
                              ).length > 0 ? "delete-selected" : ""}`}
                            key={index}
                          >
                            <td>
                              <CustomCheckbox
                                onChange={() => handleSingleItemSelect(data)}
                                checked={
                                  selecteditems.filter(
                                    (selected) =>
                                      selected.merge_image_id ==
                                      data.merge_image_id
                                  ).length > 0
                                    ? true
                                    : false
                                }
                              />
                            </td>
                            <td className="px-4 py-3">
                              {index + 1 * offset + 1}
                            </td>
                            <td className="px-4 py-3">
                              <div className="flex items-center text-sm">
                                <div className="relative nft-list rounded-lg block">
                                  <Link
                                    to={`/contract/${nft_unique_id}/nft-image/${data.merge_image_unique_id}`}
                                  >
                                    <img
                                      className="image"
                                      src={data.picture}
                                      alt=""
                                      loading="lazy"
                                    />
                                    <div
                                      className="absolute inset-0 rounded-full shadow-inner"
                                      aria-hidden="true"
                                    ></div>
                                  </Link>
                                </div>
                              </div>
                            </td>
                            <td className="px-4 py-3 text-sm">
                              <div>
                                <Link
                                  className="font-semibold"
                                  to={`/contract/${nft_unique_id}/nft-image/${data.merge_image_unique_id}`}
                                >
                                  {data.name}
                                </Link>
                              </div>
                            </td>
                            <td className="px-4 py-3 text-sm">
                              {data.mint_status == 1 ? (
                                <span
                                  className={`custom-badge ${data.mint_status_formatted.btn_class}`}
                                >
                                  {data.mint_status_formatted.status}
                                </span>
                              ) : (
                                <span className="custom-badge danger">
                                  {data.mint_status_formatted.status}
                                </span>
                              )}
                            </td>
                            <td className="px-4 py-3 text-xs">
                              {data.status == 1 ? (
                                <span className="custom-badge success">
                                  Approved
                                </span>
                              ) : (
                                <span className="custom-badge danger">
                                  Declined
                                </span>
                              )}
                            </td>
                            <td className="px-4 py-3 text-sm">
                              <div>
                                <Dropdown>
                                  <Dropdown.Toggle variant="success" id="dropdown-basic" className="action-btn">
                                    Action
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {data.mint_status == 1 && (
                                      <Dropdown.Item onClick={!auth.authStatus ? (values) => showLogin() : (event) => {
                                        mintYourNFT(data);
                                      }}>
                                        Mint Image
                                      </Dropdown.Item>
                                    )}
                                    <Dropdown.Item href={`/contract/${nft_unique_id}/nft-image/${data.merge_image_unique_id}`}>
                                      View
                                    </Dropdown.Item>

                                    <Dropdown.Item href={`/contract/${nft_unique_id}/edit-nft-image/${data.merge_image_unique_id}`}>
                                      Edit
                                    </Dropdown.Item>

                                    <Dropdown.Item onClick={() => handleNFTDelete(data.merge_image_id)}>Delete</Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
              {props.listOFNFT.data.length <= 0 && (
                <h6 className="white-color mb-0">No Data Found</h6>
              )}
              <div className="list_of_nft_pagination_wrapper">
                <span className="pagination-counter">
                  Showing {currentPage * PER_PAGE + 1} -{" "}
                  {sortby ? (
                    <>{props.listOFNFT.data.length} </>
                  ) : (
                    <>
                      {currentPage == 0
                        ? PER_PAGE
                        : offset + PER_PAGE > props.listOFNFT.total
                          ? props.listOFNFT.total
                          : offset + PER_PAGE}{" "}
                    </>
                  )}
                  of{" "}
                  {sortby ? props.listOFNFT.data.length : props.listOFNFT.total}
                </span>
                <ReactPaginate
                  previousLabel={
                    <svg
                      aria-hidden="true"
                      className="w-100"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                        fill-rule="evenodd"
                      ></path>
                    </svg>
                  }
                  nextLabel={
                    <svg
                      className="w-100"
                      aria-hidden="true"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                        fill-rule="evenodd"
                      ></path>
                    </svg>
                  }
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"list_of_nft_pagination"}
                  activeClassName={"active"}
                />
              </div>
            </>
          )}
          <MintNowModal
            show={mintNowModal}
            handleClose={handleMintNowModalClose}
            minterWalletAddress={minterWalletAddress}
            setMinterWalletAdddress={setMinterWalletAdddress}
            mintYourNFT={mintYourNFT}
            mintButtonContent={mintButtonContent}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  singleNFT: state.nft.singleNft,
  listOFNFT: state.nftImages.listOFNFT,
  deleteSingleNFT: state.nftImages.deleteSingleNFT,
  updateMintStatus: state.nftImages.updateMintStatus,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ListOfNFT);
