import React, { useEffect } from "react";
import { Notify } from "react-redux-notify";
import FooterIndex from "./Footer/FooterIndex";
import HeaderIndex from "./Header/HeaderIndex";
import Sidebar from "./Sidebar";
import { fetchUserDetailsStart } from '../store/actions/UserAction';
import { connect } from "react-redux";

const MainLayout = (props) => {


  useEffect(() => {
    if (props.profile.loading) props.dispatch(fetchUserDetailsStart());
  }, []);


  return (
    <>
      <div className="wrapper">
        <Notify position="TopRight" />
        <HeaderIndex profile={props.profile}/>
        <Sidebar profile={props.profile}/>
        {props.children}
        <FooterIndex />
      </div>
    </>
  );
}

const mapStateToPros = (state) => ({
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(MainLayout);
